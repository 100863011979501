@if (showPredefinido) {
  <span
    class="predefinido"
    translate
    [pTooltip]="'dashboard.preDefinidoTooltip' | translate"
    tooltipPosition="left"
  >
    dashboard.preDefinido
  </span>
}

<div class="container" [ngClass]="{ destacar: isDestaque }">
  <div class="p-grid">
    <div class="p-col-6">
      <span class="nome-usuario">{{ testePendenteAcaoDefinida.dsUsuario }}</span>
      <span class="cargo">{{ testePendenteAcaoDefinida.dsCargo }}</span>
      @if (showIdAcao) {
        <span class="id-acao"> ID: {{ testePendenteAcaoDefinida.idTesteExecutado }}</span>
      }
    </div>
    <div class="p-col-6 p-text-right">
      <app-icones-teste-pendente [testePendente]="testePendenteAcaoDefinida" />
    </div>
  </div>

  <div *appIfProduct>
    <div class="titulo-secao" translate>
      dashboard.detalhamento
      <span class="border"></span>
    </div>
    <div class="detalhe-item">
      <span class="parametro" translate>dashboard.acaoSugerida</span>
      <label [pTooltip]="testePendenteAcaoDefinida.dsAcaoSugerida" tooltipPosition="bottom">
        {{ testePendenteAcaoDefinida.dsAcaoSugerida }}
      </label>
    </div>
    <div class="detalhe-item">
      <span class="parametro" translate>dashboard.parametro</span>
      <label>{{ testePendenteAcaoDefinida.dsClassificacaoResultado }}</label>
    </div>
    @if (flExibirFadiga && testePendenteAcaoDefinida?.cdScoreFadiga) {
      <div class="detalhe-item">
        <span class="parametro" translate>cardAcaoNaoDefinida.scoreFadiga</span>
        <label translate> scoreFadigaEnum.{{ testePendenteAcaoDefinida.cdScoreFadiga }}</label>
      </div>
    }
  </div>

  <div>
    <button
      class="sme-primary btn-abrir-detalhes"
      type="button"
      pButton
      translate
      (click)="onClickCard(testePendenteAcaoDefinida)"
    >
      dashboard.abrirDetalhes
    </button>
  </div>
</div>
