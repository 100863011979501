<p-button
  class="download-button"
  [pTooltip]="'botaoDownload' | translate"
  styleClass="sme-secondary"
  [disabled]="loading"
  (onClick)="downloadSinalizadorProntidao()"
  [loading]="loading"
>
  <em class="fas fa-file-download"></em>
</p-button>
