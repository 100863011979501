import { ScoreFadigaEnum } from "./../enums/score-fadiga.enum";
import { FormacaoBancoDadosEnum } from "../enums/formacao-banco-dados.enum";
import { SimNaoEnum } from "../enums/sim-nao-enum";
import { Util } from "../services/util.service";
import { FiltroGraficoDashboad } from "./filtro-grafico-dashboard";

export class FiltroIndicadorFadiga {
  idHierarquia: number[];
  flIncluirFilhas: boolean = true;
  idCargo: number[];
  idTurno: number[];
  dtAnalise: Date | string;
  scoreFadiga: ScoreFadigaEnum;

  constructor() {}

  toApi() {
    const filtros = Object.assign(new FiltroIndicadorFadiga(), this);
    if (filtros.dtAnalise && typeof filtros.dtAnalise != "string") {
      filtros.dtAnalise.setHours(0, 0, 0);
    }
    filtros.dtAnalise = filtros.dtAnalise
      ? Util.dateToStringByFormat(filtros.dtAnalise as Date, "YYYY-MM-DD")
      : null;
    return filtros;
  }

  convertFromFiltroDashbaord(filtrosDashboard: FiltroGraficoDashboad) {
    this.idHierarquia = filtrosDashboard.idHierarquia;
    this.flIncluirFilhas = filtrosDashboard.flIncluirFilhas;
    this.idCargo = filtrosDashboard.idCargo;
    this.idTurno = filtrosDashboard.idTurno;
    this.dtAnalise = filtrosDashboard.dtAnalise;
    this.scoreFadiga = filtrosDashboard.scoreFadiga;
    return this;
  }
}
