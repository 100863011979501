import { Component, Input } from "@angular/core";
import {
  DetalheAcaoADefinir,
  DetalheAcaoADefinirV3,
} from "src/app/shared/models/detalhe-acao-a-definir.model";
import { DashboardService } from "src/app/shared/services/dashboard.service";

@Component({
  selector: "app-popup-evolucao-temporal",
  templateUrl: "./popup-evolucao-temporal.component.html",
  styleUrl: "./popup-evolucao-temporal.component.scss",
})
export class PopupEvolucaoTemporalComponent {
  showDialogAcaoPendente: boolean = false;
  loading: boolean = false;
  detalheAcaoADefinir: DetalheAcaoADefinirV3;

  constructor(private dashboardService: DashboardService) {}

  showPopup(idTeste: number) {
    this.showDialogAcaoPendente = true;
    this.exibirDialogAcaoPendente(idTeste);
  }

  exibirDialogAcaoPendente(idTesteExecutado: number): void {
    this.loading = true;
    this.dashboardService.carregarDadosTemporalUsuario(idTesteExecutado).subscribe(
      (resultado) => {
        this.loading = false;
        this.detalheAcaoADefinir = resultado;
        this.detalheAcaoADefinir.dtExecucao = new Date(resultado.dtExecucao);
      },
      (error) => {
        this.loading = false;
        throw error;
      },
    );
  }
}
