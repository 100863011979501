import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ItemGraficoDashboard, LISTA_GRAFICO_DASHBOARD } from "../lista-graficos-dashboard";
import { RoleService } from "src/app/shared/services/role.service";
import { Util } from "src/app/shared/services/util.service";
import { DashboardService } from "src/app/shared/services/dashboard.service";
import { FiltroGraficoDashboad } from "src/app/shared/models/filtro-grafico-dashboard";
import { parseToSimNao } from "src/app/shared/enums/sim-nao-enum";
import { DashboardState } from "../facades/dashboard.state.service";
import { Subscription } from "rxjs";
import { ResponseResumoTestesExecutadosDia } from "src/app/shared/models/response-resumo-testes-executados-dia.to";
import { PopupDetalhesResumoComponent } from "../components/popup-detalhes-resumo/popup-detalhes-resumo.component";
import { Permissao } from "src/app/shared/enums/permissao.enum";
import { ClienteUtil } from "src/app/shared/util/cliente.util";
import { ResumoDadosGrafico } from "src/app/shared/tos/response/response-resumo-dados-grafico.to";
import { FiltrosDashboardComponent } from "../components/filtros-dashboard/filtros-dashboard.component";

@Component({
  selector: "app-avaliacoes-prontidao",
  templateUrl: "./avaliacoes-prontidao.component.html",
  styleUrl: "./avaliacoes-prontidao.component.scss",
})
export class AvaliacoesProntidaoComponent implements OnInit, OnDestroy {
  @ViewChild(PopupDetalhesResumoComponent) popupDetalhesResumoComponent: PopupDetalhesResumoComponent;
  @ViewChild(FiltrosDashboardComponent) filtrosDashboardComponent: FiltrosDashboardComponent;
  @Input() loading: boolean;
  @Input() readOnly: boolean;
  @Input() isIntrajornada: boolean = false;
  @Input() exibirColunaTestes: boolean = false;

  filtros: FiltroGraficoDashboad;
  loaderResumo: boolean = false;
  listaGraficosExibir: ItemGraficoDashboard[] = [];
  resumo: ResponseResumoTestesExecutadosDia = new ResponseResumoTestesExecutadosDia();
  subs: Subscription[] = [];
  showColumaDataPopupResumo: boolean;
  labelDataUltimoTeste: string;
  exibirResumoPopup: boolean;
  tituloResumo: string;
  listaPopupResumo: ResumoDadosGrafico[];

  constructor(
    private roleService: RoleService,
    private dashboardService: DashboardService,
    private dashboardState: DashboardState,
  ) {}

  ngOnInit(): void {
    const sub1 = this.dashboardState.getFiltrosProntidao().subscribe((filtros) => {
      this.filtros = filtros;
      this.filtros.flIntrajornada = parseToSimNao(this.isIntrajornada);
      this.loadDadosDashboard(this.filtros);
    });
    this.subs.push(sub1);
  }

  private loadDadosDashboard(filtros: FiltroGraficoDashboad) {
    this.carregarGraficos(filtros);
    this.carregarResumo(filtros);
  }

  openFiltros() {
    this.filtrosDashboardComponent.showFiltros();
  }

  async carregarGraficos(filtros: any) {
    this.listaGraficosExibir = LISTA_GRAFICO_DASHBOARD.filter(
      (grafico) => grafico.permissoes.find((permissao) => this.roleService.isRole(permissao)) != undefined,
    );
    for (let grafico of this.listaGraficosExibir) {
      this.fetchGraficos(Util.deepClone(filtros), grafico);
    }
  }

  private fetchGraficos(filtros: FiltroGraficoDashboad, grafico) {
    grafico.isLoading = true;
    this.dashboardService.carregarGraficos(Util.deepClone(filtros), grafico.chave).subscribe(
      (resp) => {
        const graficoFinded = this.listaGraficosExibir.find((g) => g.chave == resp.dsChave);
        graficoFinded.isLoading = false;
        graficoFinded.dados = resp;
        grafico.hasError = false;
      },
      (err) => {
        grafico.isLoading = false;
        grafico.hasError = true;
        let dontShowGraph = false;
        if (err?.error?.find) {
          dontShowGraph = err?.error?.find((err) => err.dsErroInterno == "grafico.nao.deve.ser.exibido");
        }
        if (err.status !== 400 || !dontShowGraph) {
          throw err;
        }
      },
    );
  }

  carregarResumo(filtros) {
    this.loaderResumo = true;
    this.dashboardService.carregarResumo(filtros).subscribe(
      (resp) => {
        this.loaderResumo = false;
        this.resumo = resp;
        this.resumo.dsHierarquia = this.dashboardState.getHierarquias();
      },
      (err) => {
        this.loaderResumo = false;
        throw err;
      },
    );
  }

  recebeIdPergunta(event, obj: any) {
    const isAdministrator = this.roleService.isRole(Permissao.ADMINISTRADOR);
    const isGestorSME = this.roleService.isRole(Permissao.GESTOR_SME);
    const isGestorSMEMedicao = this.roleService.isRole(Permissao.GESTOR_SME_MEDICAO);
    const isMedico = this.roleService.isRole(Permissao.MEDICO);
    const isGestor = this.roleService.isRole(Permissao.GESTOR);
    const isBemEstar = obj?.dsChave === "QUEIXAS";
    const isEstatiscaAcoes = obj?.dsChave === "ESTATISTICA_ACOES";
    const isTermometroHumor = obj?.dsChave === "EMOCIOGRAMA";
    const isGestaoAcoes = obj?.dsChave === "GESTAO_ACOES";
    const isGestaoSaude = obj?.dsChave === "GESTAO_SAUDE";
    const handleResumo = () =>
      this.getResumo(event.id, `${obj.dsTitulo}: ${event.name}`, obj?.dsChave, event.idStr);

    if (isTermometroHumor && ClienteUtil.isCaraiba()) {
      return;
    }

    if (isBemEstar) {
      if (isAdministrator || isGestorSME || isMedico || isGestorSMEMedicao) {
        handleResumo();
      }
    } else if (
      isEstatiscaAcoes ||
      isGestaoAcoes ||
      isGestaoSaude ||
      !isGestor ||
      (isTermometroHumor && isGestor)
    ) {
      handleResumo();
    }
  }

  getResumo(id: number, titulo: string, chave: string, idStr: string) {
    const data = {
      ESTATISTICA_ACOES: this.dashboardService.getResumoEstatiscasAcoes.bind(this.dashboardService),
      RESULTADO_AVALIACAO: this.dashboardService.getResumoResultadoAvaliacao.bind(this.dashboardService),
      EMOCIOGRAMA: this.dashboardService.getResumoEmociograma.bind(this.dashboardService),
      PARAMETROS_ANALISE: this.dashboardService.getResumoParametrosAnalise.bind(this.dashboardService),
      GESTAO_SAUDE: (filtros) => {
        this.showColumaDataPopupResumo = true;
        this.labelDataUltimoTeste = "graficoBemEstar.dtUltTeste";
        return this.dashboardService.getResumoGestaoSaude(filtros);
      },
      GESTAO_ACOES: (filtros) => {
        this.showColumaDataPopupResumo = true;
        this.labelDataUltimoTeste = "graficoBemEstar.dtUltTeste";
        return this.dashboardService.getResumoGestaoAcoes(filtros);
      },
      QUEIXAS: (filtros) => {
        this.filtros.id = id;
        this.showColumaDataPopupResumo = true;
        this.labelDataUltimoTeste = "graficoBemEstar.dtUltExecucao";
        return this.dashboardService.listarGraficoBemEstar(1, 30, filtros);
      },
    };
    if (!data[chave]) {
      return;
    }
    const filtros = this.filtros.toApi(id, idStr);
    this.exibirResumoPopup = true;
    this.tituloResumo = titulo;
    this.loading = true;
    this.listaPopupResumo = [];
    this.showColumaDataPopupResumo = false;
    this.labelDataUltimoTeste = "graficoBemEstar.dtUltExecucao";
    data[chave](filtros).subscribe(
      (response) => {
        this.loading = false;
        this.listaPopupResumo = response;
      },
      (err) => {
        this.loading = false;
        throw err;
      },
    );
  }

  ngOnDestroy(): void {
    this.subs?.forEach((s) => s.unsubscribe());
    this.filtrosDashboardComponent?.fecharDialogoFiltroGrafico();
  }
}
