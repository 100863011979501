<div class="card-resumo" [ngClass]="{ disabled: disabled }">
  <p class="resumo--text">
    <span class="resumo--title"> {{ title }} </span>
    <span class="resumo--number"> {{ value }} </span>
  </p>
  @if (badgeContent) {
    <div
      class="badge"
      (mouseover)="filtrosOverlay.toggle($event)"
      (mouseleave)="filtrosOverlay.toggle($event)"
    >
      <img class="info-badge" src="/assets/images/icons/icone-info-azul.svg" />
    </div>
  }
</div>

<p-overlayPanel #filtrosOverlay styleClass="resumo-overlay">
  <ng-container *ngTemplateOutlet="badgeContent"></ng-container>
</p-overlayPanel>
