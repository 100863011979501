<app-filtros-testes-pendentes
  [listaAcoesPendentes]="listaAcoesPendentes"
  [hierarquias]="hierarquias"
  (filtrar)="handleFiltrarTestesPendentes($event)"
/>

<app-popup-definir-acao-lote
  [listaAcoes]="listaAcoes"
  [listaAcoesPendentes]="listaAcoesPendentes"
  [hierarquias]="hierarquias"
  (sucessoAcoesDefinidas)="carregarAcoesPendentes()"
  (onChangeHierarquia)="carregarAcoes($event)"
/>

<div class="card-acao-nao-definida" id="cards-acao-nao-definida-container">
  @if (exibirTitulo) {
    <div class="titulo-container" translate>
      dashboard.acoesEmAberto
      @if (!exibirTabView && nrTotalRegistros) {
        <span> ({{ nrTotalRegistros }}) </span>
      }
    </div>
  }
  @if (isExibirAcaoLote) {
    <div class="container-botoes-definir-acao-and-filtrar" data-tutorial="testes-pendente-list">
      <button type="button" class="botao-definir-acao-em-lote" (click)="exibirDialogoDefinirAcaoEmLote()">
        <span class="texto-definir-acao" translate>dashboard.definirAcaoEmLote</span>
      </button>
    </div>
  }

  @if (loading && isFirstLoad) {
    <app-loader [loading]="loading" type="normal" />
  } @else {
    <div class="position-relative">
      <button
        class="botao-filtrar"
        [class.block]="!exibirTabView"
        (click)="exibirDialogoFiltroAcaoPendenteAcaoDefinica()"
      >
        <img src="/assets/images/icons/icone-filtro.svg" />
      </button>
      <ng-container
        *ngTemplateOutlet="
          exibirTabView ? tabViewTemplate : acoesLote;
          context: { itensADefinir: testesPendentesAcaoDefinida, totalRegistros: nrTotalRegistros }
        "
      />
    </div>
  }

  <ng-template #tabViewTemplate>
    <p-tabView>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <span class="tab-title" translate
            >crudAcao.SUPERVISAO
            <span> ({{ nrTotalRegistros || 0 }}) </span>
          </span>
        </ng-template>
        <ng-container
          *ngTemplateOutlet="
            acoesLote;
            context: { totalRegistros: nrTotalRegistros, itensADefinir: testesPendentesAcaoDefinida }
          "
        ></ng-container>
      </p-tabPanel>
      @if (showTabView) {
        <p-tabPanel>
          <ng-template pTemplate="header">
            <span class="tab-title" translate
              >crudAcao.MEDICINA
              <span> ({{ nrTotalRegistrosMedicina || 0 }}) </span>
            </span>
          </ng-template>
          <ng-container
            *ngTemplateOutlet="
              acoesLote;
              context: {
                totalRegistros: nrTotalRegistrosMedicina,
                itensADefinir: testesPendentesAcaoDefinidaMedicina,
                isTesteExecutado: true,
              }
            "
          ></ng-container>
        </p-tabPanel>
      }
      @if (exibirConversaTab) {
        <p-tabPanel>
          <ng-template pTemplate="header">
            <span class="tab-title--highlight" translate
              >crudAcao.SOLICITACAO_CONVERSA
              <span> ({{ testesPendentesAcaoDefinidaConversa?.length || 0 }}) </span>
            </span>
          </ng-template>
          <ng-container
            *ngTemplateOutlet="
              acoesLote;
              context: {
                itensADefinir: testesPendentesAcaoDefinidaConversa,
                destacarCard: true,
              }
            "
          ></ng-container>
        </p-tabPanel>
      }
    </p-tabView>
  </ng-template>

  <ng-template
    #acoesLote
    let-itensADefinir="itensADefinir"
    let-isTesteExecutado="isTesteExecutado"
    let-destacarCard="destacarCard"
    let-totalRegistros="totalRegistros"
  >
    @if (totalRegistros > 50) {
      <p-paginator (onPageChange)="onPageChange($event)" rows="50" [totalRecords]="totalRegistros" />
    }
    @if (loading && !isFirstLoad) {
      <app-loader [loading]="loading" type="normal" />
    } @else {
      @if (itensADefinir?.length == 0) {
        <div id="messagePendente">
          <p-message severity="warn" [text]="'dashboard.nenhumaAvaliacaoPendente' | translate"> </p-message>
        </div>
      }
      <div class="p-grid">
        @for (item of itensADefinir; track item; let i = $index) {
          <div class="p-col-12" [ngClass]="{ 'p-md-6 p-xl-4': responsible }">
            <div data-tutorial="card-acao-pendente">
              <app-card-acao-nao-definida
                (onClick)="carregarDadosAcaoPendenteAcaoDefinica($event, i, isTesteExecutado)"
                [testePendenteAcaoDefinida]="item"
                [showIdAcao]="showIdAcao"
                [isDestaque]="destacarCard"
                [showPredefinido]="item.flPreDefinicaAcaoCadastrada && !isTesteExecutado"
              />
            </div>
          </div>
        }
      </div>
    }
  </ng-template>
</div>
