import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { KeycloakService } from "keycloak-angular";
import { Util } from "src/app/shared/services/util.service";
import { getValoresEnumTraduzidosFormacaoBancoDados } from "src/app/shared/enums/formacao-banco-dados.enum";
import { SelectItem, TreeNode } from "primeng/api";
import { HierarquiaService } from "src/app/shared/api-services/hierarquia.service";
import { TurnoService } from "src/app/shared/api-services/turno.service";
import { CargoService } from "src/app/shared/api-services/cargo.service";
import { ResponseHierarquiaTO } from "src/app/shared/tos/response-hierarquia.to";
import { CriterioInsercaoService } from "src/app/shared/services/criterio-insercao.service";
import { getListaScoreFadigaEnum } from "src/app/shared/enums/score-fadiga.enum";
import { FiltroIndicadorFadiga } from "src/app/shared/models/filtro-indicador-fadiga";

@Component({
  selector: "app-filtros-indicador-fadiga",
  templateUrl: "./filtros-indicador-fadiga.component.html",
  styleUrls: ["./filtros-indicador-fadiga.component.scss"],
})
export class FiltrosIndicadorFadigaComponent implements OnInit {
  @Output() onFiltrar = new EventEmitter();
  @Input() dadosFiltroGraficoDashboard: FiltroIndicadorFadiga = new FiltroIndicadorFadiga();

  showDialogFiltroGrafico: boolean = false;
  listaFormacaoBancoDados: SelectItem[] = [];
  listaTurnos: SelectItem[] = [];
  listaCargos: SelectItem[] = [];
  listaScoreFadiga: SelectItem[] = [];
  todasHierarquias: ResponseHierarquiaTO[];
  hierarquias: TreeNode[] = [];
  checkboxApenasUsuariosAtivos: boolean = false;
  minimoDiaMes: any;
  yearRange = new Date().getFullYear() - 3 + ":" + new Date().getFullYear();
  criteriosInsercaoPreDefinidos: any[];

  private selectsCarregados = false;

  constructor(
    private keyCloakService: KeycloakService,
    private translateService: TranslateService,
    private hierarquiaService: HierarquiaService,
    private turnoService: TurnoService,
    private cargoService: CargoService,
    private criterioInsercaoService: CriterioInsercaoService,
  ) {}

  ngOnInit() {
    this.utilizarCriterioInsercaoPreDefinido();
    this.carregarDataMinima();
  }

  utilizarCriterioInsercaoPreDefinido() {
    this.criteriosInsercaoPreDefinidos = this.criterioInsercaoService.getValoresPreDefinidos();
  }

  carregarSelects() {
    const formacaoBancoDados = getValoresEnumTraduzidosFormacaoBancoDados(this.translateService);
    this.listaFormacaoBancoDados = Util.jsonArrayToSelectItem(
      formacaoBancoDados,
      "label",
      { label: this.translateService.instant("todos"), value: null },
      "id",
    );
    this.listaScoreFadiga = Util.jsonArrayToSelectItem(
      getListaScoreFadigaEnum(this.translateService),
      "label",
      null,
      "value",
    );
    this.carregarHierarquiaTurnoCargo();
    this.selectsCarregados = true;
  }

  private async carregarHierarquiaTurnoCargo() {
    try {
      const result = await Promise.all([
        this.hierarquiaService.getAll().toPromise(),
        this.turnoService.getByHierarquia().toPromise(),
        this.cargoService.getArvoreHierarquia().toPromise(),
      ]);
      this.todasHierarquias = JSON.parse(JSON.stringify(result[0]));
      this.hierarquias = this.hierarquiaService.createTreeView(result[0]);
      if (!this.dadosFiltroGraficoDashboard?.idHierarquia?.length) {
        const idsTemorarios = this.hierarquiaService.getHierarquiasUsuario();
        this.dadosFiltroGraficoDashboard.idHierarquia = idsTemorarios;
      }
      this.listaTurnos = Util.jsonArrayToSelectItem(result[1], "dsTurno", null, "id");
      this.listaCargos = Util.jsonArrayToSelectItem(result[2], "dsCargo", null, "id");
    } catch (err) {
      console.log("Erro ao carregar hierarquia, turno e cargo", err);
      throw err;
    }
  }

  handleSubmit() {
    if (this.dadosFiltroGraficoDashboard?.idHierarquia?.length) {
      this.onFiltrar.emit(this.dadosFiltroGraficoDashboard);
      this.fecharDialogoFiltroGrafico();
    }
  }

  isFormValid() {
    return this.dadosFiltroGraficoDashboard?.idHierarquia?.length;
  }

  showFiltros() {
    this.showDialogFiltroGrafico = true;
    if (this.selectsCarregados == false) {
      this.carregarSelects();
    }
  }

  fecharDialogoFiltroGrafico() {
    this.showDialogFiltroGrafico = false;
  }

  carregarDataMinima() {
    const isAdministrator = this.keyCloakService
      .getUserRoles()
      .some((userPermission) => userPermission === "RL_ADMINISTRADOR");
    const isGestorSME = this.keyCloakService
      .getUserRoles()
      .some((userPermission) => userPermission === "RL_GESTOR_SME");
    if (isAdministrator || isGestorSME) {
      const date = new Date();
      this.minimoDiaMes = new Date(date.getFullYear() - 3, date.getMonth(), date.getDay());
    } else {
      this.minimoDiaMes = Util.subtrairDiasInData(new Date(), 90);
    }
  }
}
