import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { HierarquiaService } from "src/app/shared/api-services/hierarquia.service";
import { FiltroGraficoDashboad } from "src/app/shared/models/filtro-grafico-dashboard";

@Injectable({
  providedIn: "root",
})
export class DashboardState {
  private filtros = new BehaviorSubject<FiltroGraficoDashboad>(null);

  constructor(private hierarquiaService: HierarquiaService) {
    this.initFiltrosProntidao();
  }

  initFiltrosProntidao() {
    const filtros = new FiltroGraficoDashboad().toApi();
    filtros.idHierarquia = this.hierarquiaService.getHierarquiasUsuario();
    this.setFiltrosProntidao(filtros);
  }

  getFiltrosProntidao() {
    return this.filtros;
  }

  setFiltrosProntidao(filtros: FiltroGraficoDashboad) {
    this.filtros.next(filtros);
  }

  getHierarquias() {
    return this.hierarquiaService
      .getAllSync()
      ?.filter((h) => !!this.filtros.value.idHierarquia.find((filtroHierarquia) => filtroHierarquia == h.id))
      ?.map((item) => item.dsHierarquia);
  }
}
