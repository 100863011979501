<div class="header-container">
  <app-aviso-hierarquia-temporaria />

  <div class="botoes-wrapper">
    <app-repetir-tutorial (repetirTutorial)="onRepetirTutorial.emit()" />

    @if (exibirBotaoToggleColuna) {
      <button
        class="sme-icon hide-mobile"
        (click)="toggleColunaTestes.emit()"
        [class.show-teste]="exibirColunaTestes"
      >
        <em class="material-icons">chevron_left</em>
      </button>
    }
  </div>
</div>
