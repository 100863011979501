import { NotificationService } from "./../../../shared/services/notification.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DetalheAcaoADefinirV3 } from "src/app/shared/models/detalhe-acao-a-definir.model";
import { DashboardService } from "src/app/shared/services/dashboard.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { TestePendenteService } from "../teste-pendente.service";

@Component({
  selector: "app-testes-pendentes",
  templateUrl: "./testes-pendentes.component.html",
  styleUrls: ["./testes-pendentes.component.scss"],
})
export class TestesPendentesComponent implements OnInit, OnDestroy {
  detalheAcaoADefinir: DetalheAcaoADefinirV3;
  loaderDetalhe: boolean;

  constructor(
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private routerService: RouterService,
    private translateService: TranslateService,
    private testePendenteService: TestePendenteService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((p) => {
      const id = this.route.snapshot.params["id"];
      if (this.isNumeric(id)) {
        this.carregarTestePendente(id);
        this.testePendenteService.notificarTesteSelecionado(id);
      } else {
        this.voltar();
        this.notificationService.notifyError(
          this.translateService.instant("dashboard.testePendenteInvalido"),
        );
      }
    });
  }

  isNumeric(id: number) {
    return !isNaN(id);
  }

  carregarTestePendente(idTeste: number) {
    this.loaderDetalhe = true;
    this.dashboardService.carregarDadosUsuario(idTeste).subscribe(
      (resultado) => {
        this.detalheAcaoADefinir = resultado;
        this.detalheAcaoADefinir.dtExecucao = new Date(resultado.dtExecucao);
        this.loaderDetalhe = false;
      },
      (error) => {
        this.loaderDetalhe = false;
        this.detalheAcaoADefinir = null;
        this.voltar();
        throw error;
      },
    );
  }

  voltar() {
    const isMobile = window.innerWidth < 1200;
    if (isMobile) {
      this.routerService.toListaTestePendente();
    } else {
      this.routerService.toDashboard();
    }
  }

  ngOnDestroy() {
    this.testePendenteService.notificarTesteSelecionado(null);
  }

  onSuggestActionSuccess() {
    this.testePendenteService.onTesteSaveScucess();
    this.voltar();
  }
}
