<p-accordion>
  <p-accordionTab [header]="'dashboard.historicoAlteracoesAcaoDefinida' | translate">
    <p-table styleClass="p-datatable-striped" [value]="historico">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 108px" translate scope="col">dashboard.dtAcaoDefinidaAnt</th>
          <th translate scope="col">dashboard.dtAcaoDefinidaNova</th>
          <th translate scope="col">dashboard.dsAcaoDefinidaAnt</th>
          <th translate scope="col">dashboard.dsAcaoDefinidaNova</th>
          <th translate scope="col">dashboard.cdMatriculaUsuarioNova</th>
          <th translate scope="col">dashboard.dsJustificativaAcaoNova</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-historico>
        <tr>
          <td>{{ historico.dtAcaoDefinidaAnt | date: "short" }}</td>
          <td>{{ historico.dtAcaoDefinidaNova | date: "short" }}</td>
          <td>{{ historico.dsAcaoDefinidaAnt }}</td>
          <td>{{ historico.dsAcaoDefinidaNova }}</td>
          <td>{{ historico.cdMatriculaUsuarioNova }}</td>
          <td>
            <div class="comentario-wrapper">
              <span class="comentario">
                {{ historico.dsJustificativaAcaoNova || "-" }}
              </span>
              @if (historico.dsJustificativaAcaoNova) {
                <button
                  pButton
                  class="sme-text"
                  (click)="exibirComentarioCompleto(historico.dsJustificativaAcaoNova)"
                >
                  <img src="/assets/images/icons/icone-olho.svg" />
                </button>
              }
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-accordionTab>
</p-accordion>

<p-dialog
  [header]="'dashboard.dsJustificativaAcaoNova' | translate"
  [showHeader]="true"
  [modal]="true"
  [draggable]="false"
  [style]="{ width: '90%', 'max-width': '1100px', height: '90vh' }"
  [(visible)]="showPopupComentarioCompleto"
>
  {{ comentarioCompleto }}
</p-dialog>
