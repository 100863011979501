<app-novidades-notificacao (novidadesExibidas)="startTutorial($event)"></app-novidades-notificacao>

<div class="container">
  <app-header-dashboard
    [exibirBotaoToggleColuna]="
      configuracaoDashboard.podeVerPendentestes && (tabProntidao?.selected || tabIntrajornada?.selected)
    "
    [exibirColunaTestes]="exibirColunaTestes"
    (onRepetirTutorial)="onRepetirTutorial()"
    (toggleColunaTestes)="toggleColunaTestes()"
  />
  <app-alerta-dashboard class="hide-xl" />
  <!--CONTAINER DOS GRAFICOS-->
  <div data-tutorial="dashboard-container" class="container-graficos">
    <div class="botao-filtrar-aba">
      <button (click)="exibirDialogoFiltroGrafico()" class="sme-text">
        <img src="/assets/images/icons/icone-filtro.svg" />
      </button>
      <button (click)="imprimirPagina()" class="sme-text">
        <img src="/assets/images/icons/icone-print.svg" />
      </button>
    </div>

    <div style="width: 100%">
      <p-tabView id="dashboard-tab" class="tabView" (onChange)="handleChange()" [(activeIndex)]="activeTab">
        <p-tabPanel
          class="tabPanel_jornada"
          #tabProntidao
          [tooltip]="'dashboard.hintAbaAvaliacoes' | translate"
          [header]="'dashboard.abaAvaliacoes' | translate"
        >
          @if (activeTab == 0) {
            <app-avaliacoes-prontidao
              [loading]="loaderResumo"
              [isIntrajornada]="false"
              [exibirColunaTestes]="exibirColunaTestes"
            />
          }
        </p-tabPanel>
        @if (configuracaoDashboard.exibirAbaIntrajornada) {
          <p-tabPanel
            #tabIntrajornada
            class="tabPanel_intrajornada"
            [tooltip]="'dashboard.hintAbaFadiga' | translate"
          >
            <ng-template pTemplate="header">
              <span data-tutorial="aba-intrajornada" translate> dashboard.abaFadiga </span>
            </ng-template>
            @if (tabIntrajornada?.selected) {
              <app-avaliacoes-prontidao
                [loading]="loaderResumo"
                [isIntrajornada]="true"
                [exibirColunaTestes]="exibirColunaTestes"
              />
            }
          </p-tabPanel>
        }
        @if (configuracaoDashboard.exibirAbaSegurancaPsicologica) {
          <p-tabPanel class="tabPanel_jornada" #tabSeguranca>
            <ng-template pTemplate="header">
              <span data-tutorial="aba-seguranca" class="aba-seguranca">
                <span translate> dashboard.abaSeguranca </span>
              </span>
            </ng-template>
            @if (tabSeguranca?.selected) {
              <app-seguranca-psicologica #segurancaPsicologica />
            }
          </p-tabPanel>
        }
        @if (configuracaoDashboard.exibirAbaSinalizadorProntidao) {
          <p-tabPanel class="tabPanel_jornada" #tabSinalizadorProntidao>
            <ng-template pTemplate="header">
              <span data-tutorial="aba-sinalizador-prevencao" class="aba-seguranca">
                <span translate> sinalizador.tituloAba </span>
                <img class="info-badge" src="/assets/images/icons/icone-info-azul.svg" />
              </span>
            </ng-template>
            @if (tabSinalizadorProntidao?.selected) {
              <app-sinalizador-prontidao #sinalizadorProntidao />
            }
          </p-tabPanel>
        }
        @if (configuracaoDashboard.exibirAbaFadiga) {
          <p-tabPanel class="tabPanel_jornada" #tabFadiga>
            <ng-template pTemplate="header">
              <span data-tutorial="aba-indicador-fadiga" translate> dashboard.abaIndicadorFadiga </span>
            </ng-template>
            @if (tabFadiga?.selected) {
              <app-indicadores-fadiga #indicadorFadiga />
            }
          </p-tabPanel>
        }
      </p-tabView>
    </div>
  </div>

  <p-toast [style]="{ marginTop: '80px' }" position="top-right" key="tc"></p-toast>
</div>
