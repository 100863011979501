import { ResponseUsuarioSimplesTO } from "../tos/response/response-usuario-simple.to";

export class ResponseResumoTestesExecutadosDia {
  qtTotal: number;
  qtRealizado: number;
  qtPendente: number;
  qtTotalAusencia: number;
  qtTotalEscala: number;
  qtTotalGeral: number;
  usuario?: ResponseUsuarioSimplesTO;
  dsHierarquia?: string[];
}
