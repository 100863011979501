import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { DetalheAcaoADefinir, DetalheAcaoADefinirV3 } from "../models/detalhe-acao-a-definir.model";
import { GraficoDashboard } from "../models/grafico-dashboard.model";
import { RequestDefinirAcaoLoteTO } from "../models/request-definir-acao-em-lote.to";
import { ResponsePaginadoPendenteAcaoDefinidaTO } from "../models/response-paginado-pendente-acao-defina.to";
import { ResponseResumoTestesExecutadosDia } from "../models/response-resumo-testes-executados-dia.to";
import { ResponseResumoUsuarioPendenteDiaPaginadoTO } from "../models/response-resumo-usuario-pendente-dia-paginado-to";
import { ResponseResumoAvaliacoesRealizadasDiaPaginadoTO } from "../tos/response/response-resumo-avaliacoes-realizadas-dia-paginado-to";
import { TesteExecutadoAcaoDefinida } from "../models/teste-executado-acao-definida.model";
import { ResponseResumoUsuarioTO } from "../tos/response/response-resumo-usuario.to";
import { ResponseResumoDadosQueixasPaginadoTO } from "../tos/response/response-resumo-dados-queixas-paginado-to";
import { SimNaoEnum } from "../enums/sim-nao-enum";
import { ResponseGraficoSegurancaPsicologicaTO } from "../tos/response/response-grafico-segurancao-psicologica.to";
import { FiltroGraficoDashboad } from "../models/filtro-grafico-dashboard";
import { Util } from "./util.service";
import { TipoComparacaoFadigaEnum } from "../enums/TipoComparacaoFadiga.enum";
import { ResponseGraficoIndicadorFadigaTO } from "../tos/response/response-grafico-indicador-fadiga.to";
import { ResponseUsuariosIndicadorFadigaTO } from "../tos/response/response-usuarios-indicador-fadiga.to";
import { FiltroIndicadorFadiga } from "../models/filtro-indicador-fadiga";
import { ResponseGraficoIndicadorFadigaByUsuarioTo } from "../tos/response/response-grafico-inficador-fadiga-by-usuario";
import { ResumoDadosGrafico } from "../tos/response/response-resumo-dados-grafico.to";
import { map } from "rxjs/operators";
import { FiltroAcoesPendentes } from "../models/filtro-acoes-pendentes";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private httpClient: HttpClient) {}

  carregarGraficos(dadosFiltro: any = {}, graficoChave: string): Observable<GraficoDashboard> {
    let url: string = `/v2/teste-executado/graficos?`;
    const cloneDadosFiltro = { ...dadosFiltro };

    if (dadosFiltro) {
      delete cloneDadosFiltro["dataInicial"];
      delete cloneDadosFiltro["dataFinal"];
    }

    cloneDadosFiltro.graficoChave = graficoChave;
    url += Util.objectToQueryString(cloneDadosFiltro);
    return this.httpClient.get<GraficoDashboard>(url);
  }

  carregarAcoesPendentesAcaoDefinida(
    filtroAcoesPendentes: FiltroAcoesPendentes,
    nrPagina: number,
    qtRegistrosPagina: number = 50,
  ): Observable<ResponsePaginadoPendenteAcaoDefinidaTO> {
    let url: string = `/v1/teste-executado/pendente?`;
    if (filtroAcoesPendentes) {
      url += Util.objectToQueryString(filtroAcoesPendentes);
    }
    url += `&${Util.objectToQueryString({
      nrPagina,
      qtRegistrosPagina,
    })}`;
    return this.httpClient.get<ResponsePaginadoPendenteAcaoDefinidaTO>(url);
  }

  carregarDadosUsuario(idTesteExecutado: number): Observable<DetalheAcaoADefinirV3> {
    return this.httpClient.get<DetalheAcaoADefinirV3>(`/v3/teste-executado/pendente/${idTesteExecutado}`);
  }

  carregarDadosTemporalUsuario(idTesteExecutado: number): Observable<DetalheAcaoADefinirV3> {
    return this.httpClient.get<DetalheAcaoADefinirV3>(
      `/v3/teste-executado/dadosTemporal/${idTesteExecutado}`,
    );
  }

  definirAcao(
    idTesteExecutado: number,
    acaoDefinidaTesteExecutado: TesteExecutadoAcaoDefinida,
  ): Observable<any> {
    return this.httpClient.put<[]>(
      `/v1/teste-executado/${idTesteExecutado}/definir-acao`,
      acaoDefinidaTesteExecutado,
    );
  }

  preDefinirAcao(
    idTesteExecutado: number,
    acaoDefinidaTesteExecutado: TesteExecutadoAcaoDefinida,
  ): Observable<any> {
    return this.httpClient.put<[]>(
      `/v1/teste-executado/${idTesteExecutado}/pre-definir-acao`,
      acaoDefinidaTesteExecutado,
    );
  }

  definirAcaoEmLote(requestDefinirAcaoLoteTO: RequestDefinirAcaoLoteTO): Observable<any> {
    return this.httpClient.put<[]>(`/v1/teste-executado/definir-acao-lote`, requestDefinirAcaoLoteTO);
  }

  carregarResumo(dadosFiltro?: any): Observable<ResponseResumoTestesExecutadosDia> {
    let url = "/v1/teste-executado/resumo?";
    if (dadosFiltro) {
      const cloneDadosFiltro = { ...dadosFiltro };
      delete cloneDadosFiltro["dataInicial"];
      delete cloneDadosFiltro["dataFinal"];
      url += Util.objectToQueryString(cloneDadosFiltro);
    }
    return this.httpClient.get<ResponseResumoTestesExecutadosDia>(url);
  }

  listarUsuariosPendentes(
    nrPagina: number,
    qtRegistrosPagina: number,
    filtros: FiltroGraficoDashboad,
  ): Observable<ResponseResumoUsuarioPendenteDiaPaginadoTO> {
    let url = "/v1/teste-executado/resumo-usuarios-pendentes/";
    let params = Util.objectToQueryString(filtros);

    if (nrPagina) {
      url += "?nrPagina=" + nrPagina;
    }

    if (qtRegistrosPagina) {
      url += "&qtRegistrosPagina=" + qtRegistrosPagina;
    }
    return this.httpClient.get<ResponseResumoUsuarioPendenteDiaPaginadoTO>(url, { params });
  }

  listarUsuariosCadastrados(
    nrPagina: number,
    qtRegistrosPagina: number,
    filtros: FiltroGraficoDashboad,
  ): Observable<ResponseResumoUsuarioTO> {
    let url = "/v1/teste-executado/resumo-usuarios-cadastrados/";
    let params = Util.objectToQueryString(filtros);

    if (nrPagina) {
      url += "?nrPagina=" + nrPagina;
    }

    if (qtRegistrosPagina) {
      url += "&qtRegistrosPagina=" + qtRegistrosPagina;
    }

    return this.httpClient.get<ResponseResumoUsuarioTO>(url, { params });
  }

  listarAvaliacoesRealizadas(
    nrPagina: number,
    qtRegistrosPagina: number,
    filtros: FiltroGraficoDashboad,
  ): Observable<ResponseResumoAvaliacoesRealizadasDiaPaginadoTO> {
    let url = "/v1/teste-executado/resumo-avaliacoes-realizadas/";
    let params = Util.objectToQueryString(filtros);

    if (nrPagina) {
      url += "?nrPagina=" + nrPagina;
    }

    if (qtRegistrosPagina) {
      url += "&qtRegistrosPagina=" + qtRegistrosPagina;
    }
    return this.httpClient.get<ResponseResumoAvaliacoesRealizadasDiaPaginadoTO>(url, { params });
  }

  listarGraficoBemEstar(
    nrPagina: number,
    qtRegistrosPagina: number,
    {
      idHierarquia,
      flIncluirFilhas,
      idCargo,
      idTurno,
      id,
      dtInicio,
      dtFim,
      situacaoImpulsividadeEstimada,
      idNivelResultado,
      cdMatriculaUsuario,
      flIntrajornada,
    }: FiltroGraficoDashboad,
  ): Observable<ResponseResumoDadosQueixasPaginadoTO> {
    let url = `/v1/teste-executado/resumo-dados-queixa/`;

    if (nrPagina) {
      url += "?nrPagina=" + nrPagina;
    }
    if (dtInicio) {
      url += "&dtInicio=" + dtInicio;
    }
    if (dtFim) {
      url += "&dtFim=" + dtFim;
    }

    if (idNivelResultado) {
      url += "&idNivelResultado=" + idNivelResultado;
    }

    if (situacaoImpulsividadeEstimada) {
      url += "&situacaoImpulsividadeEstimada=" + situacaoImpulsividadeEstimada;
    }

    if (qtRegistrosPagina) {
      url += "&qtRegistrosPagina=" + qtRegistrosPagina;
    }
    if (flIncluirFilhas != undefined) {
      url += "&flIncluirFilhas=" + flIncluirFilhas;
    }
    if (id) {
      url += "&id=" + id;
    }
    if (cdMatriculaUsuario) {
      url += "&cdMatriculaUsuario=" + cdMatriculaUsuario;
    }
    if (idHierarquia) {
      idHierarquia.forEach((hierarquia) => {
        url += "&idHierarquia=" + hierarquia;
      });
    }
    if (idCargo) {
      idCargo.forEach((cargo) => {
        url += "&idCargo=" + cargo;
      });
    }
    if (idTurno) {
      idTurno.forEach((turno) => {
        url += "&idTurno=" + turno;
      });
    }
    if (flIntrajornada != undefined) {
      url += "&flIntrajornada=" + flIntrajornada;
    }

    return this.httpClient.get<ResponseResumoDadosQueixasPaginadoTO>(url);
  }

  getResumoEstatiscasAcoes(filtros: FiltroGraficoDashboad): Observable<ResumoDadosGrafico[]> {
    const params = Util.objectToQueryString(filtros);
    return this.httpClient.get<ResumoDadosGrafico[]>(`/v1/teste-executado/resumo-dados-estatistica-acoes/`, {
      params,
    });
  }

  getResumoGestaoSaude(filtros: FiltroGraficoDashboad): Observable<ResumoDadosGrafico[]> {
    const params = Util.objectToQueryString(filtros);
    return this.httpClient.get<ResumoDadosGrafico[]>(`/v1/teste-executado/resumo-gestao-saude/`, {
      params,
    });
  }

  getResumoResultadoAvaliacao(filtros: FiltroGraficoDashboad): Observable<ResumoDadosGrafico[]> {
    const params = Util.objectToQueryString(filtros);
    return this.httpClient.get<ResumoDadosGrafico[]>(`/v1/teste-executado/resumo-resultado-avaliacao`, {
      params,
    });
  }

  getResumoEmociograma(filtros: FiltroGraficoDashboad): Observable<ResumoDadosGrafico[]> {
    const params = Util.objectToQueryString(filtros);
    return this.httpClient.get<ResumoDadosGrafico[]>(`/v1/teste-executado/resumo-emociograma`, { params });
  }

  getResumoParametrosAnalise(filtros: FiltroGraficoDashboad): Observable<ResumoDadosGrafico[]> {
    const params = Util.objectToQueryString(filtros);
    return this.httpClient.get<ResumoDadosGrafico[]>(`/v1/teste-executado/resumo-parametros-analise`, {
      params,
    });
  }

  getResumoGestaoAcoes(filtros: FiltroGraficoDashboad): Observable<ResumoDadosGrafico[]> {
    const params = Util.objectToQueryString(filtros);
    return this.httpClient.get<ResumoDadosGrafico[]>(`/v1/teste-executado/resumo-gestao-acoes`, { params });
  }

  deleteTesteExecutado(idTeste: number): Observable<any> {
    return this.httpClient.delete(`/v1/teste-executado/${idTeste}`);
  }

  getGraficoSegurancaPsicologica(
    filtros: FiltroGraficoDashboad,
  ): Observable<ResponseGraficoSegurancaPsicologicaTO[]> {
    const params = Util.objectToQueryString(filtros);
    return this.httpClient.get<ResponseGraficoSegurancaPsicologicaTO[]>(
      `/v1/teste-executado/grafico-seguranca-psicologica`,
      { params },
    );
  }

  private calcularTotalTeste(dados: any[]) {
    if (!dados) return 0;
    return dados.reduce((prev, cur) => cur.qtRegistros + prev, 0);
  }

  getGraficoIndicadorFadiga(filtros: FiltroIndicadorFadiga): Observable<ResponseGraficoIndicadorFadigaTO[]> {
    let params = Util.objectToQueryString(filtros);
    return this.httpClient
      .get<ResponseGraficoIndicadorFadigaTO[]>(`/v1/teste-executado/graficos-fadiga`, { params })
      .pipe(
        map((resultado) => {
          resultado.forEach((graficoIndicadorFadiga) => {
            graficoIndicadorFadiga.qtdTotalTeste = this.calcularTotalTeste(graficoIndicadorFadiga.dados);
          });
          return resultado;
        }),
      );
  }

  getUsuariosIndicadorFadiga(
    filtros: FiltroIndicadorFadiga,
    nrPagina: number,
    qtRegistrosPagina: number,
    cdTipoComparacaoFadiga: TipoComparacaoFadigaEnum,
  ): Observable<ResponseUsuariosIndicadorFadigaTO> {
    let params = Util.objectToQueryString(filtros);
    params = params.append("cdTipoComparacaoFadiga", cdTipoComparacaoFadiga);
    params = params.append("nrPagina", nrPagina);
    params = params.append("qtRegistrosPagina", qtRegistrosPagina);
    return this.httpClient.get<ResponseUsuariosIndicadorFadigaTO>(`/v1/teste-executado/usuarios-fadiga`, {
      params,
    });
  }

  getGraficoByUsuario(idUsuario: number): Observable<ResponseGraficoIndicadorFadigaByUsuarioTo> {
    return this.httpClient.get<ResponseGraficoIndicadorFadigaByUsuarioTo>(
      `/v1/teste-executado/graficos-fadiga-usuario/${idUsuario}`,
    );
  }
}
