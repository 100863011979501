import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ParametroEnum } from "src/app/shared/enums/parametro-enum.ts";
import { DetalheAcaoADefinirV3 } from "src/app/shared/models/detalhe-acao-a-definir.model";
import { ResponseTesteExecutadoHistoricoParametrosTO } from "src/app/shared/tos/response-teste-executado-historico-parametros.to";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-detalhe-acao-a-definir",
  templateUrl: "detalhe-acao-a-definir.component.html",
  styleUrls: ["detalhe-acao-a-definir.component.scss"],
})
export class DetalheAcaoADefinirComponent implements OnInit, OnChanges {
  @Input() isLoading: boolean;
  @Input() detalheAcaoADefinir: DetalheAcaoADefinirV3;

  exibeId: boolean = false;
  parametrosPrincipais: ResponseTesteExecutadoHistoricoParametrosTO[] = [];
  outrosParametros: ResponseTesteExecutadoHistoricoParametrosTO[] = [];
  prontidaoGeral: number;

  constructor(public translateService: TranslateService) {}

  ngOnInit() {
    this.exibeId = environment.cliente == "vale";
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.detalheAcaoADefinir?.currentValue) {
      this.separarParametros(this.detalheAcaoADefinir.parametros);
    }
  }

  separarParametros(parametros: ResponseTesteExecutadoHistoricoParametrosTO[]) {
    if (parametros?.length) {
      this.parametrosPrincipais = parametros.filter(this.isParametroPrincipal);
      this.outrosParametros = parametros.filter(
        (p) => !this.isParametroPrincipal(p) && p.nmParametro != ParametroEnum.PRONTIDAO_GERAL,
      );
      this.prontidaoGeral = parametros.find((p) => p.nmParametro == ParametroEnum.PRONTIDAO_GERAL)?.vlPerc;
    }
  }

  isParametroPrincipal(parametro: ResponseTesteExecutadoHistoricoParametrosTO) {
    const principais = [
      ParametroEnum.ATENCAO,
      ParametroEnum.CONCENTRACAO,
      ParametroEnum.CONTROLE_IMPULSO,
      ParametroEnum.TEMPO_REACAO,
      ParametroEnum.COMP_INSEGURO,
    ];
    return principais.includes(parametro.nmParametro);
  }
}
