<div class="teste-executado-container">
  <app-loader [loading]="loaderDetalhe" />

  @if (!loaderDetalhe && detalheAcaoADefinir) {
    <app-detalhe-acao-a-definir [detalheAcaoADefinir]="detalheAcaoADefinir" (voltar)="voltar()" />
    <app-form-comentario-saude
      [idTesteExecutado]="detalheAcaoADefinir.idTesteExecutado"
      (cancel)="voltar()"
      (successTesteExecutado)="onSuggestActionSuccess()"
    />
  }
</div>
