import { Component, OnDestroy, OnInit } from "@angular/core";
import { AlertaSegurancaService } from "src/app/shared/api-services/alerta-seguranca.service";
import { SimNaoEnum } from "src/app/shared/enums/sim-nao-enum";

@Component({
  selector: "app-alerta-dashboard",
  templateUrl: "./alerta-dashboard.component.html",
  styleUrls: ["./alerta-dashboard.component.scss"],
})
export class AlertaDashboardComponent implements OnInit, OnDestroy {
  showModal: boolean = false;
  isAlerta: boolean = false;

  constructor(private alertaService: AlertaSegurancaService) {}

  ngOnInit(): void {
    this.verificarAlerta();
  }

  verificarAlerta() {
    this.alertaService.getAlertaSeguranca().subscribe((r) => {
      this.isAlerta = r?.flAlerta == SimNaoEnum.SIM;
      if (this.isAlerta) {
        this.setCorBackground("#fff3c1");
      }
    });
  }

  openModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  setCorBackground(cor: string) {
    const html = document.getElementsByTagName("html")[0];
    html.style.backgroundColor = cor;
  }

  ngOnDestroy() {
    this.setCorBackground(null);
  }
}
