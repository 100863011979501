<p-dialog
  [header]="'entrevistaDirecionada.titulo' | translate"
  [(visible)]="show"
  [modal]="true"
  [closable]="true"
  [style]="{ width: '90%', 'max-width': '850px' }"
>
  <app-loader [loading]="loading" />

  @if (!loading && !perguntas?.length) {
    <div class="not-found" translate>entrevistaDirecionada.naoEncontrada</div>
  }

  <form #form="ngForm">
    @if (!loading) {
      @if (readOnly && !respostas?.length) {
        <div class="p-text-center" style="font-size: 18px" translate>entrevistaDirecionada.naoRespondido</div>
      } @else {
        @for (pergunta of perguntas; track pergunta; let i = $index) {
          <div
            #perguntasView
            class="pergunta-content"
            [ngClass]="{ 'nao-respondido': destacarNaoRespondidas && !respostas[i].flOpcao }"
          >
            <label> {{ i + 1 }}. {{ pergunta.dsPergunta }} </label>
            <div class="radio-wrapper">
              <div class="radio-content">
                <p-radioButton
                  [inputId]="i + 'sim'"
                  [name]="'pergunta' + i"
                  value="SIM"
                  [(ngModel)]="respostas[i].flOpcao"
                  [disabled]="readOnly"
                />
                <label [for]="i + 'sim'" translate class="label-input"> simNaoEnum.sim </label>
              </div>
              <div class="radio-content">
                <p-radioButton
                  [inputId]="i + 'nao'"
                  [name]="'pergunta' + i"
                  value="NAO"
                  [(ngModel)]="respostas[i].flOpcao"
                  [disabled]="readOnly"
                />
                <label [for]="i + 'nao'" translate class="label-input"> simNaoEnum.nao </label>
              </div>
            </div>
            @if (pergunta.dsOrientacaoNao && respostas[i].flOpcao == "NAO") {
              <div class="dica-content">
                <em class="pi pi-info-circle"></em>
                <span [innerHtml]="pergunta.dsOrientacaoNao"></span>
              </div>
            }
            @if (pergunta.dsOrientacaoSim && respostas[i].flOpcao == "SIM") {
              <div class="dica-content">
                <em class="pi pi-info-circle"></em>
                <span [innerHtml]="pergunta.dsOrientacaoSim"></span>
              </div>
            }
          </div>
        }
      }
    }
    @if (!readOnly) {
      <div class="footer">
        @if (dicas?.length) {
          <label translate class="dica-titulo"> entrevistaDirecionada.importante </label>
        }
        <ul class="dicas-list">
          @for (dica of dicas; track dica) {
            <li class="dica">*{{ dica.dsDica }}</li>
          }
        </ul>
        <div class="button-content">
          <button
            type="button"
            (click)="enviarRespostas()"
            [disabled]="!form.valid"
            pButton
            class="sme-quaternary"
            [label]="'botaoSalvar' | translate"
          ></button>
        </div>
      </div>
    }
  </form>
</p-dialog>
