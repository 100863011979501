import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-header-dashboard",
  templateUrl: "./header-dashboard.component.html",
  styleUrl: "./header-dashboard.component.scss",
})
export class HeaderDashboardComponent {
  @Output() toggleColunaTestes = new EventEmitter();
  @Output() onRepetirTutorial = new EventEmitter();
  @Input() exibirBotaoToggleColuna = false;
  @Input() exibirColunaTestes = false;
}
