import { Component, Input } from "@angular/core";

@Component({
  selector: "app-parametro-card",
  templateUrl: "./parametro-card.component.html",
  styleUrl: "./parametro-card.component.scss",
})
export class ParametroCardComponent {
  @Input() tooltip: string;
  @Input() descricao: string;
  @Input() valor: number;

  getIcone() {
    if (this.valor < 30) {
      return "/assets/images/icons/icone-circulo-correto.svg";
    } else if (this.valor < 70) {
      return "/assets/images/icons/icone-alerta.svg";
    } else {
      return "/assets/images/icons/icone-circulo-seta-baixo.svg";
    }
  }
}
