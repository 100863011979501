import { Component, Input } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { AcaoDefinir } from "src/app/shared/models/acao-definir.model";

@Component({
  selector: "app-card-definir-acao",
  templateUrl: "./card-definir-acao.component.html",
  styleUrl: "./card-definir-acao.component.scss",
})
export class CardDefinirAcaoComponent {
  @Input() acoes: AcaoDefinir[];
  @Input() form: FormControl;
  @Input() acaoSugerida: string;

  definirAcaoForm: FormControl = new FormControl(null, [Validators.required]);
  exibirPopupDefinirAcao: boolean = false;

  abrirDefinirAcao() {
    this.exibirPopupDefinirAcao = true;
    this.definirAcaoForm.patchValue(this.form.value);
  }

  handleSubmit() {
    if (this.definirAcaoForm.valid) {
      this.form.patchValue(this.definirAcaoForm.value);
      this.exibirPopupDefinirAcao = false;
    }
  }

  getAcaoLabel(id: number) {
    return this.acoes.find((acao) => acao.id == id)?.dsAcao;
  }
}
