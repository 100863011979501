<p-accordion>
  <p-accordionTab [header]="'dashboard.historicoDeacoes' | translate">
    <p-table styleClass="p-datatable-striped" [value]="historicoAcoes" id="histocioAcoesTable">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 108px" translate scope="col">dashboard.data</th>
          <th translate scope="col">dashboard.acaoSugerida</th>
          <th translate scope="col">dashboard.acaoDefinida</th>
          <th style="width: 108px" translate scope="col">dashboard.dtAcaoDefinida</th>
          <th translate scope="col">dashboard.dsJustificativaAcao</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-historico>
        <tr>
          <td style="width: 108px">
            {{ historico.dtExecucao | date: "short" }}
          </td>
          <td>{{ historico.dsAcaoSugerida }}</td>
          <td>{{ historico.dsAcaoDefinida }}</td>
          <td style="width: 108px">
            {{ historico.dtAcaoDefinida ? (historico.dtAcaoDefinida | date: "short") : "-" }}
          </td>
          <td>
            <div class="comentario-wrapper">
              <span class="comentario">
                {{ historico.dsJustificativaAcao || "-" }}
              </span>
              @if (historico.dsJustificativaAcao) {
                <button
                  pButton
                  class="sme-text"
                  (click)="exibirComentarioCompleto(historico.dsJustificativaAcao)"
                >
                  <img src="/assets/images/icons/icone-olho.svg" />
                </button>
              }
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-accordionTab>
</p-accordion>

<p-dialog
  [header]="'dashboard.dsJustificativaAcaoNova' | translate"
  [showHeader]="true"
  [modal]="true"
  [draggable]="false"
  [style]="{ width: '90%', 'max-width': '1100px', height: '90vh' }"
  [(visible)]="showPopupComentarioCompleto"
>
  {{ comentarioCompleto }}
</p-dialog>
