import { Component, HostListener, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Chart } from "angular-highcharts";
import { DetalheAcaoADefinirV3 } from "src/app/shared/models/detalhe-acao-a-definir.model";
import {
  DadosGraficoHistoricoTestes,
  GraficoHistoricoTestesService,
  TipoGraficoHistoricoTeste,
} from "./grafic-historico-testes.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-grafico-historico-testes",
  templateUrl: "./grafico-historico-testes.component.html",
  styleUrl: "./grafico-historico-testes.component.scss",
  providers: [GraficoHistoricoTestesService, DatePipe],
})
export class GraficoHistoricoTestesComponent implements OnChanges {
  @Input() detalheAcaoADefinir: DetalheAcaoADefinirV3;
  @Input() prontidaoGeral: number = 0;
  chart: Chart;
  dadosGrafico: DadosGraficoHistoricoTestes;

  constructor(private graficoHistoricoService: GraficoHistoricoTestesService) {}

  @HostListener("window:resize")
  onResize() {
    if (this.dadosGrafico) {
      this.chart = this.graficoHistoricoService.configurarGrafico(this.dadosGrafico);
    }
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.dadosGrafico = this.graficoHistoricoService.prepararDadosParaGrafico(this.detalheAcaoADefinir);
    setTimeout(() => {
      this.chart = this.graficoHistoricoService.configurarGrafico(this.dadosGrafico);
    });
  }

  filtrarGrafico(ev: any) {
    this.graficoHistoricoService.filtrar(
      this.chart,
      ev.target.value as TipoGraficoHistoricoTeste,
      ev.target.checked,
    );
  }
}
