<div class="informacoes-card">
  <div class="cabecalho">
    <h3 class="titulo" translate>dashboard.informacoesDia</h3>
    <span class="id"> <span translate> dashboard.ID</span>: {{ testeADefinir.idTesteExecutado }} </span>
    <app-icones-teste-pendente [testePendente]="testeADefinir" />
  </div>
  <div class="detalhamento-container">
    <span translate> dashboard.detalhamento </span>
    <div class="borda"></div>
  </div>
  <div class="informacoes-detalhes">
    <p class="informacao-wrapper">
      <span class="label" translate> dashboard.acaoSugerida </span>
      <span class="valor"> {{ testeADefinir.dsAcaoSugerida }} </span>
    </p>
    <p class="informacao-wrapper">
      <span class="label" translate> dashboard.parametroDesvio </span>
      <span class="valor"> {{ testeADefinir.dsClassificaoGrupo }} </span>
    </p>
    <p class="informacao-wrapper">
      <span class="label" translate> dashboard.indiceFadiga </span>
      <span class="valor"> {{ testeADefinir.dsClassificaoFadiga }} </span>
    </p>
  </div>
</div>
