export enum SinalizadorOrdenacaoEnum {
  NOME_ASC = "NOME_ASC",
  NOME_DESC = "NOME_DESC",
  MATRICULA_ASC = "MATRICULA_ASC",
  MATRICULA_DESC = "MATRICULA_DESC",
  HIERARQUIA_ASC = "HIERARQUIA_ASC",
  HIERARQUIA_DEC = "HIERARQUIA_DEC",
  QTD_USUARIO_ASC = "QTD_USUARIO_ASC",
  QTD_USUARIO_DESC = "QTD_USUARIO_DESC",
  QTD_AVALIACOES_ASC = "QTD_AVALIACOES_ASC",
  QTD_AVALIACOES_DESC = "QTD_AVALIACOES_DESC",
  PC_NIVEL_NORMALIDADE_ASC = "PC_NIVEL_NORMALIDADE_ASC",
  PC_NIVEL_NORMALIDADE_DESC = "PC_NIVEL_NORMALIDADE_DESC",
  PC_DEFINICAO_ACAO_ASC = "PC_DEFINICAO_ACAO_ASC",
  PC_DEFINICAO_ACAO_DESC = "PC_DEFINICAO_ACAO_DESC",
  PC_NIVEL_ALTERADO_ASC = "PC_NIVEL_ALTERADO_ASC",
  PC_NIVEL_ALTERADO_DESC = "PC_NIVEL_ALTERADO_DESC",
  PC_ACOES_CRITICAS_ASC = "PC_ACOES_CRITICAS_ASC",
  PC_ACOES_CRITICAS_DESC = "PC_ACOES_CRITICAS_DESC",
  QT_QUEIXAS_USUARIOS_ASC = "QT_QUEIXAS_USUARIOS_ASC",
  QT_QUEIXAS_USUARIOS_DESC = "QT_QUEIXAS_USUARIOS_DESC",
  PC_PREDITOR_RISCO_ASC = "PC_PREDITOR_RISCO_ASC",
  PC_PREDITOR_RISCO_DESC = "PC_PREDITOR_RISCO_DESC",
  PC_FADICA_ASC = "PC_FADICA_ASC",
  PC_FADICA_DESC = "PC_FADICA_DESC",
  QT_SCORE_TOTAL_ASC = "QT_SCORE_TOTAL_ASC",
  QT_SCORE_TOTAL_DESC = "QT_SCORE_TOTAL_DESC",
}

export const parseSinalizadorOrdenacaoEnum = (coluna: string, direcao: string): SinalizadorOrdenacaoEnum => {
  const _direcao = direcao == "up" ? "DESC" : "ASC";
  const colunasMap = {
    dsHierarquia: "NOME",
    cdMatricula: "MATRICULA",
    dsNomeHierarquia: "HIERARQUIA",
    qtUsuarios: "QTD_USUARIO",
    qtAvaliacoes: "QTD_AVALIACOES",
    pcNivelNormalidade: "PC_NIVEL_NORMALIDADE",
    pcDefinicaoAcao: "PC_DEFINICAO_ACAO",
    pcNivelAlterado: "PC_NIVEL_ALTERADO",
    pcAcoesCriticas: "PC_ACOES_CRITICAS",
    qtQueixasUsuarios: "QT_QUEIXAS_USUARIOS",
    pcPreditorRisco: "PC_PREDITOR_RISCO",
    pcFadiga: "PC_FADICA",
    qtScoreTotal: "QT_SCORE_TOTAL",
  };
  return (colunasMap[coluna] + "_" + _direcao) as SinalizadorOrdenacaoEnum;
};
