import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { CardChartDashboardComponent } from "./dashboard/components/card-chart-dashboard/card-chart-dashboard.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { InformativoComponent } from "./informativo/informativo.component";
import { PagesRoutingModule } from "./pages-routing.module";
import { RedirectAuthComponent } from "./redirect-auth/redirect-auth.component";
import { ResultadosComponent } from "./resultados/resultados.component";
import { TooltipModule } from "primeng/tooltip";
import { DetalheAcaoADefinirModule } from "./detalhe-acao-a-definir/detalhe-acao-a-definir.module";
import { SegurancaPsicologicaComponent } from "./dashboard/seguranca-psicologica/seguranca-psicologica.component";
import { BoxDashboardComponent } from "./dashboard/components/box-dashboard/box-dashboard.component";
import { GraficoSegurancaPsicologicaComponent } from "./dashboard/components/grafico-seguranca-psicologica/grafico-seguranca-psicologica.component";
import { IndicadoresFadigaComponent } from "./dashboard/indicadores-fadiga/indicadores-fadiga.component";
import { BoxTermometroComponent } from "./dashboard/components/box-termometro/box-termometro.component";
import { AlertaDashboardComponent } from "./dashboard/components/alerta-dashboard/alerta-dashboard.component";
import { NovidadesModule } from "./novidades/novidades.module";
import { ResumoCardComponent } from "./dashboard/components/resumo-card/resumo-card.component";
import { ResumoBadgeContentComponent } from "./dashboard/components/resumo-badge-content/resumo-badge-content.component";
import { GraficoResumoPopupComponent } from "./dashboard/components/grafico-resumo-popup/grafico-resumo-popup.component";
import { SkeletonModule } from "primeng/skeleton";
import { CardChartContainerComponent } from "./dashboard/components/card-chart-container/card-chart-container.component";
import { SinalizadorProntidaoComponent } from "./dashboard/components/sinalizador-prontidao/sinalizador-prontidao.component";
import { TabelaSinalizadorProntidaoComponent } from "./dashboard/components/sinalizador-prontidao/tabela-sinalizador-prontidao/tabela-sinalizador-prontidao.component";
import { MediaSinalizadorProntidaoComponent } from "./dashboard/components/sinalizador-prontidao/media-sinalizador-prontidao/media-sinalizador-prontidao.component";
import { FiltrosSinalizadorProntidaoComponent } from "./dashboard/components/sinalizador-prontidao/filtros-sinalizador-prontidao/filtros-sinalizador-prontidao.component";
import { IndicadorFadigaCardModule } from "../shared/components/indicador-fadiga-card/indicador-fadiga-card.module";
import { LegendaSinalizadorProntidaoComponent } from "./dashboard/components/sinalizador-prontidao/legenda-sinalizador-prontidao/legenda-sinalizador-prontidao.component";
import { TestesModule } from "./testes/testes.module";
import { GraficoIndicadorFadigaComponent } from "./dashboard/components/grafico-indicador-fadiga/grafico-indicador-fadiga.component";
import { HistoricoFeriasComponent } from "./aviso-de-ausencia/historico-ferias/historico-ferias.component";
import { AvisoAusenciaComponent } from "./aviso-de-ausencia/aviso-de-ausencia.component";
import { DownloadSinalizadorProntidaoComponent } from "./dashboard/components/sinalizador-prontidao/download-sinalizador-prontidao/download-sinalizador-prontidao.component";
import { CompiladoSinalizadorProntidaoComponent } from "./dashboard/components/sinalizador-prontidao/compilado-sinalizador-prontidao/compilado-sinalizador-prontidao.component";
import { HeaderDashboardComponent } from "./dashboard/components/header-dashboard/header-dashboard.component";
import { InformacoesHeaderComponent } from "./dashboard/components/informacoes-header/informacoes-header.component";
import { AvaliacoesProntidaoComponent } from "./dashboard/avaliacoes-prontidao/avaliacoes-prontidao.component";
import { PopupEvolucaoTemporalComponent } from "./dashboard/components/popup-evolucao-temporal/popup-evolucao-temporal.component";
import { PopupDetalhesResumoComponent } from "./dashboard/components/popup-detalhes-resumo/popup-detalhes-resumo.component";
import { FiltrosDashboardComponent } from "./dashboard/components/filtros-dashboard/filtros-dashboard.component";
import { FiltrosIndicadorFadigaComponent } from "./dashboard/components/filtros-indicador-fadiga/filtros-indicador-fadiga.component";

const components = [
  RedirectAuthComponent,
  DashboardComponent,
  CardChartDashboardComponent,
  ResultadosComponent,
];

@NgModule({
  imports: [
    SharedModule,
    PagesRoutingModule,
    TooltipModule,
    TestesModule,
    DetalheAcaoADefinirModule,
    NovidadesModule,
    SkeletonModule,
    IndicadorFadigaCardModule,
    GraficoIndicadorFadigaComponent,
    CompiladoSinalizadorProntidaoComponent,
  ],
  declarations: [
    components,
    InformativoComponent,
    AvisoAusenciaComponent,
    FiltrosDashboardComponent,
    FiltrosIndicadorFadigaComponent,
    HistoricoFeriasComponent,
    SegurancaPsicologicaComponent,
    BoxDashboardComponent,
    GraficoSegurancaPsicologicaComponent,
    IndicadoresFadigaComponent,
    BoxTermometroComponent,
    ResumoCardComponent,
    ResumoBadgeContentComponent,
    AlertaDashboardComponent,
    GraficoResumoPopupComponent,
    CardChartContainerComponent,
    SinalizadorProntidaoComponent,
    TabelaSinalizadorProntidaoComponent,
    MediaSinalizadorProntidaoComponent,
    FiltrosSinalizadorProntidaoComponent,
    LegendaSinalizadorProntidaoComponent,
    DownloadSinalizadorProntidaoComponent,
    HeaderDashboardComponent,
    InformacoesHeaderComponent,
    AvaliacoesProntidaoComponent,
    PopupEvolucaoTemporalComponent,
    PopupDetalhesResumoComponent,
  ],
})
export class PagesModule {}
