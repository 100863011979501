<p-dialog
  [header]="'analiseProntidao.GraficoEvolucaoTemporal' | translate"
  id="idDialogAcaoPendente"
  [modal]="true"
  [style]="{ width: '90%', 'max-width': '1100px' }"
  [showHeader]="true"
  [(visible)]="showDialogAcaoPendente"
  blockScroll="true"
>
  <app-loader [loading]="loading" />
  @if (detalheAcaoADefinir) {
    <app-detalhe-acao-a-definir [detalheAcaoADefinir]="detalheAcaoADefinir" />
  } @else if (!loading && !detalheAcaoADefinir) {
    <span translate> analiseProntidao.erroComentarioSaude </span>
  }
</p-dialog>
