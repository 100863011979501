import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ResponseResumoTestesExecutadosDia } from "src/app/shared/models/response-resumo-testes-executados-dia.to";
import { PopupEvolucaoTemporalComponent } from "../popup-evolucao-temporal/popup-evolucao-temporal.component";

@Component({
  selector: "app-informacoes-header",
  templateUrl: "./informacoes-header.component.html",
  styleUrl: "./informacoes-header.component.scss",
})
export class InformacoesHeaderComponent {
  @ViewChild(PopupEvolucaoTemporalComponent) popupEvolucaotemporalComponent: PopupEvolucaoTemporalComponent;
  @Input() detalhes: ResponseResumoTestesExecutadosDia;

  exibirEvolucaoTemporal() {
    this.popupEvolucaotemporalComponent.showPopup(this.detalhes.usuario.idUltimoTesteExecutado);
  }
}
