import { Component, Input } from "@angular/core";
import { DetalheAcaoADefinirV3 } from "src/app/shared/models/detalhe-acao-a-definir.model";

@Component({
  selector: "app-informacoes-dia",
  templateUrl: "./informacoes-dia.component.html",
  styleUrl: "./informacoes-dia.component.scss",
})
export class InformacoesDiaComponent {
  @Input() testeADefinir: DetalheAcaoADefinirV3;
}
