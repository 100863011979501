<app-loader [loading]="loadingTeste" />
@if (!loadingTeste && testeExecutado) {
  <div class="p-grid">
    <div class="p-col-6">
      <p class="label">
        <strong>
          <span translate>analiseProntidao.nome</span>:
          {{ testeExecutado?.dsNome }}
        </strong>
        <br />
        <strong>
          <span translate>analiseProntidao.matricula</span>:
          {{ testeExecutado?.cdMatricula }}
        </strong>
      </p>
    </div>
    <div class="p-col-6">
      <button
        pButton
        class="sme-secondary botaoBaixarRelatorioTeste"
        [disabled]="loadButtonRelatorioTeste"
        (click)="gerarRelatorioTeste()"
        [pTooltip]="'analiseProntidao.tituloBotaoDownload' | translate"
        tooltipPosition="top"
        [loading]="loadButtonRelatorioTeste"
      >
        <em class="fas fa-file-download"></em>
      </button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12">
      <label translate class="label definirAcao">analiseProntidao.dsAcaoSugerida</label>
      <input type="text" [(ngModel)]="testeExecutado.dsAcaoSugerida" readonly />
    </div>
    <div class="p-col-12">
      <label class="label definirAcao" translate>analiseProntidao.dsJustificativaAcao</label>
      @for (comentario of comentariosSupervisor; track comentario) {
        <textarea type="text" [ngModel]="comentario" readonly></textarea>
      }
    </div>
    <div class="p-col-12">
      <label class="label definirAcao" translate>analiseProntidao.dsAcaoDefinida</label>
      <input type="text" [(ngModel)]="testeExecutado.dsAcaoDefinida" readonly />
    </div>
    <div class="p-col-12">
      <div class="container-item-linha" style="width: 100%">
        <span class="label definirAcao" translate>analiseProntidao.definirAcaoMedicina</span>
        <app-dropdown
          [options]="acoesDefinidas"
          name="acoesDefinidasSelected"
          [(ngModel)]="requestTesteExecutadoComentarioMedicoTO.idAcaoDefinida"
        >
        </app-dropdown>
      </div>
    </div>
    <div class="p-col-12">
      <span class="label definirAcao" translate>analiseProntidao.comentarioSaude</span>
      <textarea
        [(ngModel)]="requestTesteExecutadoComentarioMedicoTO.dsLaudoMedico"
        rows="5"
        style="height: 77px !important"
        name="inputComentarioMedico"
      ></textarea>
    </div>
    <div class="p-col-6 p-md-3">
      <span class="label definirAcao" translate>analiseProntidao.pressaoArterialSistole</span>
      <input
        type="text"
        [(ngModel)]="requestTesteExecutadoComentarioMedicoTO.dsPressaoArterialSistole"
        maxlength="5"
        name="dsPressaoArterialSistole"
      />
    </div>
    <div class="p-col-6 p-md-3">
      <span class="label definirAcao" translate>analiseProntidao.pressaoArterialDiastole</span>
      <input
        type="text"
        maxlength="5"
        [(ngModel)]="requestTesteExecutadoComentarioMedicoTO.dsPressaoArterialDiastole"
        name="dsPressaoArterialDiastole"
      />
    </div>
    <div class="p-col-6 p-md-3">
      <span class="label definirAcao" translate>analiseProntidao.frequenciaCardiaca</span>
      <input
        type="text"
        maxlength="5"
        [(ngModel)]="requestTesteExecutadoComentarioMedicoTO.dsFrequenciaCardiaca"
        name="dsFrequenciaCardiaca"
      />
    </div>
    <div class="p-col-6 p-md-3">
      <span class="label definirAcao" translate>analiseProntidao.frequenciaRespiratoria</span>
      <input
        type="text"
        maxlength="5"
        [(ngModel)]="requestTesteExecutadoComentarioMedicoTO.dsFrequenciaRespiratoria"
        name="dsFrequenciaRespiratoria"
      />
    </div>
    <div class="p-col-6 p-md-3">
      <span class="label definirAcao" translate>analiseProntidao.temperatura</span>
      <input
        type="text"
        maxlength="5"
        [(ngModel)]="requestTesteExecutadoComentarioMedicoTO.dsTemperatura"
        name="dsTemperatura"
      />
    </div>
    <div class="p-col-6 p-md-3">
      <span class="label definirAcao" translate>analiseProntidao.medicamento</span>
      <input
        type="text"
        maxlength="50"
        [(ngModel)]="requestTesteExecutadoComentarioMedicoTO.dsMedicamento"
        name="dsMedicamento"
      />
    </div>
    <div class="p-col-6 p-md-3">
      <span class="label definirAcao" translate>analiseProntidao.queixa</span>
      <input
        type="text"
        maxlength="50"
        [(ngModel)]="requestTesteExecutadoComentarioMedicoTO.dsQueixa"
        name="queixa"
      />
    </div>
    <div class="p-col-6 p-md-3">
      <span class="label definirAcao" translate>analiseProntidao.peso</span>
      <input
        type="text"
        maxlength="5"
        [(ngModel)]="requestTesteExecutadoComentarioMedicoTO.dsPeso"
        name="dsPeso"
      />
    </div>
    <div class="p-col-6 p-md-3">
      <span class="label definirAcao" translate>analiseProntidao.altura</span>
      <input
        type="text"
        maxlength="5"
        [(ngModel)]="requestTesteExecutadoComentarioMedicoTO.dsAltura"
        name="dsAltura"
      />
    </div>
    <div class="p-col-6 p-md-3">
      <span class="label definirAcao" translate>analiseProntidao.circunferenciaAbnominal</span>
      <input
        type="text"
        maxlength="5"
        [(ngModel)]="requestTesteExecutadoComentarioMedicoTO.dsCircunferenciaAbnominal"
        name="dsCircunferenciaAbnominal"
      />
    </div>
    <div class="p-col-6 p-md-3">
      <span class="label definirAcao" translate>analiseProntidao.cervical</span>
      <input
        type="text"
        maxlength="5"
        [(ngModel)]="requestTesteExecutadoComentarioMedicoTO.dsCervical"
        name="dsCervical"
      />
    </div>
    <div class="p-col-6 p-md-3">
      <span class="label definirAcao" translate>analiseProntidao.dsGlicemia</span>
      <input
        type="text"
        maxlength="5"
        [(ngModel)]="requestTesteExecutadoComentarioMedicoTO.dsGlicemia"
        name="dsGlicemia"
      />
    </div>
    <div class="p-col-6 p-md-3">
      <span class="label definirAcao" translate>analiseProntidao.dsTesteAlcoolemia</span>
      <input
        type="text"
        maxlength="5"
        [(ngModel)]="requestTesteExecutadoComentarioMedicoTO.dsTesteAlcoolemia"
        name="dsTesteAlcoolemia"
      />
    </div>
    <div class="p-col-6 p-md-3">
      <span class="label definirAcao" translate>analiseProntidao.dsTestesSaudeAdicionais</span>
      <input
        type="text"
        maxlength="100"
        [(ngModel)]="requestTesteExecutadoComentarioMedicoTO.dsTestesSaudeAdicionais"
        name="dsTestesSaudeAdicionais"
      />
    </div>
    <div class="p-col-6 p-md-3">
      <span class="label definirAcao" translate>analiseProntidao.dsSaturacao</span>
      <input
        type="text"
        maxlength="100"
        [(ngModel)]="requestTesteExecutadoComentarioMedicoTO.dsSaturacao"
        name="dsSaturacao"
      />
    </div>
    <div class="p-col-12">
      <p-accordion class="accordionAvaliacoes">
        <p-accordionTab [header]="'analiseProntidao.avaliacaoPerceptiva' | translate">
          <app-questionario-avaliacao
            [model]="requestTesteExecutadoComentarioMedicoTO"
            [questionario]="avaliacaoPerceptiva"
          />
        </p-accordionTab>
        <p-accordionTab [header]="'analiseProntidao.avaliacaoMental' | translate">
          <app-questionario-avaliacao
            [model]="requestTesteExecutadoComentarioMedicoTO"
            [questionario]="avaliacaoMental"
          />
        </p-accordionTab>
        <p-accordionTab [header]="'analiseProntidao.avaliacaoMotora' | translate">
          <app-questionario-avaliacao
            [model]="requestTesteExecutadoComentarioMedicoTO"
            [questionario]="avaliacaoMotora"
          />
        </p-accordionTab>
        <p-accordionTab [header]="'analiseProntidao.avaliacaoGeral' | translate">
          <app-questionario-avaliacao
            [model]="requestTesteExecutadoComentarioMedicoTO"
            [questionario]="avaliacaoGeral"
          />
        </p-accordionTab>
      </p-accordion>
    </div>
    <div class="p-col-12">
      <p-accordion class="accordionSaude">
        <p-accordionTab [header]="'analiseProntidao.historicoSaude' | translate">
          <p-table [value]="testeExecutado.laudoMedicoHitoricos" responsiveLayout="scroll">
            <ng-template pTemplate="header">
              <tr>
                <th translate>analiseProntidao.laudoMedicoHistoricos.dtHistorico</th>
                <th translate>analiseProntidao.laudoMedicoHistoricos.dsAcaoDefinidaLaudoMedico</th>
                <th translate>analiseProntidao.laudoMedicoHistoricos.dsLaudoMedico</th>
                <th translate>analiseProntidao.laudoMedicoHistoricos.dsNomeUsuarioLaudoMedico</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-laudo>
              <tr class="historico-row">
                <td>{{ laudo.dtHistorico | date: "short" }}</td>
                <td>{{ laudo.dsAcaoDefinidaLaudoMedico }}</td>
                <td>{{ laudo.dsLaudoMedico }}</td>
                <td>{{ laudo.dsNomeUsuarioLaudoMedico }}</td>
              </tr>
            </ng-template>
          </p-table>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
  <div class="p-col-12">
    <div style="text-align: center">
      <button
        type="button"
        [label]="'botaoCancelar' | translate"
        pButton
        class="botao-branco"
        [disabled]="loading"
        style="margin-right: 20px"
        (click)="cancel.emit()"
      ></button>
      <button
        name="submit"
        type="submit"
        [label]="'botaoSalvar' | translate"
        pButton
        class="botao-salvar"
        [disabled]="loading"
        (click)="onSubmit()"
        style="margin-right: 20px"
        [loading]="loading"
      ></button>
    </div>
  </div>
}
@if (!loadingTeste && !testeExecutado) {
  <div class="p-grid">
    <div class="p-col-12">
      <p translate class="erro-comentario">analiseProntidao.erroComentarioSaude</p>
      <button
        type="button"
        [label]="'botaoCancelar' | translate"
        pButton
        class="botao-branco p-d-block p-m-auto"
        [disabled]="loading"
        (click)="cancel.emit()"
      ></button>
    </div>
  </div>
}
