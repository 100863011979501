import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SelectItem, TreeNode } from "primeng/api";
import { Subscription } from "rxjs";
import { AcaoService } from "src/app/shared/api-services/acao.service";
import { ContratoService } from "src/app/shared/api-services/contrato.service";
import { HierarquiaService } from "src/app/shared/api-services/hierarquia.service";
import { TipoMessageEnum } from "src/app/shared/enums/tipo-message.enum";
import { ResponsePaginadoPendenteAcaoDefinidaTO } from "src/app/shared/models/response-paginado-pendente-acao-defina.to";
import { ResponsePendenteAcaoDefinidaTO } from "src/app/shared/models/response-pendente-acao-definida.to";
import { DashboardService } from "src/app/shared/services/dashboard.service";
import { MessageService } from "src/app/shared/services/message.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { TopbarService } from "src/app/shared/services/topbar.service";
import { Util } from "src/app/shared/services/util.service";
import { TestePendenteService } from "../../teste-pendente.service";
import { FiltrosTestesPendentesComponent } from "../filtros-testes-pendentes/filtros-testes-pendentes.component";
import { PopupDefinirAcaoLoteComponent } from "../popup-definir-acao-lote/popup-definir-acao-lote.component";
import { FiltrarAreaDestinoPipe } from "./filtrar-area-destino.pipe";
import { FiltroGraficoDashboad } from "src/app/shared/models/filtro-grafico-dashboard";
import { UserGuideService } from "src/app/shared/services/user-guide.service";
import { testesPendentesListTutorial } from "./testes-pendentes-list.tutorial";
import { TipoDispositivo } from "src/app/shared/enums/tipo-dispositivo.enum";
import { TipoDispositivoService } from "src/app/shared/services/tipo-dispositivo.service";
import { ActivatedRoute } from "@angular/router";
import { UserGuideEnum } from "src/app/shared/enums/user-guide.enum";
import { RoleService } from "src/app/shared/services/role.service";
import { Permissao } from "src/app/shared/enums/permissao.enum";
import { NotificationService } from "src/app/shared/services/notification.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { DashboardState } from "src/app/pages/dashboard/facades/dashboard.state.service";

@Component({
  selector: "app-testes-pendentes-list",
  templateUrl: "./testes-pendentes-list.component.html",
  styleUrls: ["./testes-pendentes-list.component.scss"],
  providers: [UserGuideService, FiltrarAreaDestinoPipe],
})
export class TestesPendentesListComponent implements OnInit, OnDestroy {
  @ViewChild(FiltrosTestesPendentesComponent)
  filtrosTesteComponente: FiltrosTestesPendentesComponent;
  @ViewChild(PopupDefinirAcaoLoteComponent)
  popupDefinirAcaoLoteComponente: PopupDefinirAcaoLoteComponent;
  @Input() responsible = true;
  @Input() exibirTitulo = true;

  testesPendentesAcaoDefinida: ResponsePendenteAcaoDefinidaTO[] = [];
  testesPendentesAcaoDefinidaMedicina: ResponsePendenteAcaoDefinidaTO[] = [];
  testesPendentesAcaoDefinidaConversa: ResponsePendenteAcaoDefinidaTO[] = [];
  showTabView: boolean = false;
  loading: boolean = false;
  isExibirAcaoLote: boolean = false;
  showIdAcao: boolean = false;
  exibirConversaTab: boolean = false;

  listaAcoesPendentes: SelectItem[] = [];
  hierarquias: TreeNode[] = [];
  listaAcoes: SelectItem[] = [
    {
      label: this.translateService.instant("dashboard.nenhumAcaoDefinida"),
      value: null,
    },
  ];

  idsHierarquiasTemporarias: number[];
  idSelecionado: number;
  subs: Subscription[] = [];

  dadosFiltroTestePendente: any = {
    idHierarquia: this.hierarquiaService.getHierarquiasUsuario(),
  };
  dadosFiltroDashboardSaved: FiltroGraficoDashboad = new FiltroGraficoDashboad();
  private selectsCarregados = false;

  nrPagina: number = 0;
  nrTotalRegistros: number;
  nrTotalRegistrosMedicina: number;
  isFirstLoad = true;

  constructor(
    private dashboardService: DashboardService,
    private acaoService: AcaoService,
    private translateService: TranslateService,
    private topbarService: TopbarService,
    private hierarquiaService: HierarquiaService,
    private router: RouterService,
    private testePendenteService: TestePendenteService,
    private messageService: MessageService,
    private contratoService: ContratoService,
    private userGuideService: UserGuideService,
    private tipoDispositivoService: TipoDispositivoService,
    private route: ActivatedRoute,
    private roleService: RoleService,
    private notificationService: NotificationService,
    private tutorialService: UserGuideService,
    private sessionStorageService: SessionStorageService,
    private dashboardState: DashboardState,
  ) {}

  ngOnInit() {
    this.setTutorialSteps();
    this.carregarConfiguracao();
    const sub = this.testePendenteService.testeSelecionado.subscribe((id) => (this.idSelecionado = id));
    if (this.responsible) {
      this.carregarAcoesPendentes();
    } else {
      const sub2 = this.dashboardState.getFiltrosProntidao().subscribe((filtrosDashboard) => {
        this.dadosFiltroDashboardSaved = Util.deepClone(filtrosDashboard);
        this.carregarAcoesPendentes();
      });
      this.subs.push(sub2);
    }
    if (this.route.snapshot.queryParams["continueTutorial"] == "true") {
      this.userGuideService.repeat();
    }
    this.subs.push(sub);
  }

  get exibirTabView() {
    return this.showTabView || this.exibirConversaTab;
  }

  setTutorialSteps() {
    const navegarParaDashboard = () => this.router.toDashboard(true);
    this.userGuideService.setSteps(
      testesPendentesListTutorial(
        this.tipoDispositivoService.isMobile() || this.tipoDispositivoService.isTablet(),
        navegarParaDashboard,
      ),
    );
  }

  private carregarConfiguracao() {
    this.contratoService.getContrato().subscribe((r) => {
      this.showIdAcao = r.flExibeIdTesteImpedimentoResultado;
      this.exibirConversaTab = r.flExibeSolicitarApoio;
    });
  }

  private async carregarHierarquia() {
    try {
      const result = await Promise.all([this.hierarquiaService.getAll().toPromise()]);
      this.selectsCarregados = true;
      this.hierarquias = this.hierarquiaService.createTreeView(result[0]);
      this.idsHierarquiasTemporarias = this.hierarquiaService.getHierarquiasUsuario();
    } catch (err) {
      console.log("Erro ao carregar selects", err);
      throw err;
    }
  }

  carregarAcoes(idHierarquia: number) {
    this.listaAcoes = [
      {
        label: this.translateService.instant("dashboard.nenhumAcaoDefinida"),
        value: null,
      },
    ];
    this.acaoService.getAcoesByIdHierarquia(idHierarquia).subscribe((result) => {
      this.listaAcoes = Util.jsonArrayToSelectItem(result, "dsAcao", {
        label: this.translateService.instant("dashboard.nenhumAcaoDefinida"),
        value: null,
      });
    });
  }

  handleShowTab(responseAcaosAdefinir: ResponsePaginadoPendenteAcaoDefinidaTO): boolean {
    return responseAcaosAdefinir.flExibirAcoesMedicina;
  }

  onPageChange(event) {
    this.nrPagina = event.page;
    this.carregarAcoesPendentes();
  }

  async carregarAcoesPendentes() {
    try {
      const filtros = Object.assign({}, this.dadosFiltroTestePendente, this.dadosFiltroDashboardSaved);
      delete filtros.dtFim;
      delete filtros.dtInicio;
      delete filtros.dtAnalise;
      delete filtros.flApenasUsuarioAtivos;
      this.loading = true;
      const result = await this.dashboardService
        .carregarAcoesPendentesAcaoDefinida(filtros, this.nrPagina + 1)
        .toPromise();
      this.loading = false;
      this.isFirstLoad = false;
      this.nrTotalRegistros = result.qtTotalRegistros;
      this.nrTotalRegistrosMedicina = result.qtTotalRegistrosMedicina;
      this.showTabView = this.handleShowTab(result);
      this.tratarAcoesPendentesAcaoDefinida(result);
      this.exibirMensagemAcoes(result);
    } catch (err) {
      this.loading = false;
      console.log("Erro ao carregar acoes pendentes");
      throw err;
    }
  }

  exibirMensagemAcoes(responsePaginadoPendenteAcaoDefinida: ResponsePaginadoPendenteAcaoDefinidaTO) {
    if (this.tutorialService.isVisible()) return;
    const isSupervisor = this.roleService.isRole(Permissao.SUPERVISOR);
    const isAdm = this.roleService.isRole(Permissao.ADMINISTRADOR);
    const isSupervisorChecklist = this.roleService.isRole(Permissao.SUPERVISOR_CHECKLIST);
    const qtdTotal = responsePaginadoPendenteAcaoDefinida.dados?.length;
    const qtdNotificar =
      responsePaginadoPendenteAcaoDefinida.dados?.filter((d) => d.flNotificar)?.length || 0;
    const msgSaved = this.sessionStorageService.getItem<any>("NOTIFICAR_ACOES_PENDENTES");
    const hasChanged = msgSaved?.qtdTotal != qtdTotal || msgSaved?.qtdNotificar != qtdNotificar;
    if (hasChanged && qtdNotificar > 0 && (isSupervisor || isAdm || isSupervisorChecklist)) {
      const translate = this.translateService
        .instant("dashboard.msgQtTotalRegistros")
        .replace("{0}", qtdTotal)
        .replace("{1}", qtdNotificar);
      this.notificationService.notifyInfo(translate);
      this.sessionStorageService.setItem("NOTIFICAR_ACOES_PENDENTES", { qtdTotal, qtdNotificar });
    }
  }

  handleFiltrarTestesPendentes(filtrosTestePendentes) {
    this.dadosFiltroTestePendente = filtrosTestePendentes;
    this.carregarAcoesPendentes();
  }

  exibirDialogoFiltroAcaoPendenteAcaoDefinica() {
    this.filtrosTesteComponente.exibirFiltros();
    if (this.selectsCarregados == false) {
      this.carregarHierarquia();
    }
  }

  exibirDialogoDefinirAcaoEmLote() {
    this.popupDefinirAcaoLoteComponente.exibirPopup();
    if (this.selectsCarregados == false) {
      this.carregarHierarquia();
    }
  }

  private filtrarSolicitacaoConversa(dados: ResponsePendenteAcaoDefinidaTO[], possuiSolicitacao: boolean) {
    return dados?.filter((d) => d.flApoioSolicitado == possuiSolicitacao);
  }

  /**
   * Carrega os dados de ação pendente de ação definida.
   */
  private tratarAcoesPendentesAcaoDefinida(
    responsePaginadoPendenteAcaoDefinida: ResponsePaginadoPendenteAcaoDefinidaTO,
  ): void {
    this.testesPendentesAcaoDefinida =
      this.filtrarSolicitacaoConversa(responsePaginadoPendenteAcaoDefinida.dados, false) || [];
    this.testesPendentesAcaoDefinidaConversa =
      this.filtrarSolicitacaoConversa(responsePaginadoPendenteAcaoDefinida.dados, true) || [];
    this.testesPendentesAcaoDefinidaMedicina = responsePaginadoPendenteAcaoDefinida.dadosMedicina || [];
    this.listaAcoesPendentes = Util.jsonArrayToSelectItem(
      responsePaginadoPendenteAcaoDefinida.acoesPendentes,
      "dsAcao",
    );
    this.topbarService.broadcast(this.testesPendentesAcaoDefinida.length);
  }

  carregarDadosAcaoPendenteAcaoDefinica(
    teste: ResponsePendenteAcaoDefinidaTO,
    index: number,
    isTesteExecutado: boolean,
  ) {
    this.idSelecionado = teste.idTesteExecutado;
    if (isTesteExecutado) {
      this.router.toTesteExecutado(teste.idTesteExecutado);
    } else {
      this.router.toTestePendente(teste.idTesteExecutado);
    }
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }
}
