import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { ContratoService } from "src/app/shared/api-services/contrato.service";
import { Column } from "src/app/shared/models/column.model";
import { FiltroGraficoDashboad } from "src/app/shared/models/filtro-grafico-dashboard";
import { DashboardService } from "src/app/shared/services/dashboard.service";
import { ResponseResumoUsuarioTO } from "src/app/shared/tos/response/response-resumo-usuario.to";

export enum TipoPoupResumo {
  USUARIOS_ATIVIDADE = "USUARIOS_ATIVIDADE",
  AVALIACOES_REALIZADAS = "AVALIACOES_REALIZADAS",
  USUARIOS_SEM_AVALIACAO = "USUARIOS_SEM_AVALIACAO",
}

@Component({
  selector: "app-popup-detalhes-resumo",
  templateUrl: "./popup-detalhes-resumo.component.html",
  styleUrl: "./popup-detalhes-resumo.component.scss",
  providers: [DatePipe],
})
export class PopupDetalhesResumoComponent implements OnInit {
  @Input() filtros: FiltroGraficoDashboad;

  header: string;
  colunas: Column[] = [];
  exibirPopup: boolean = false;
  exibirIdTeste: boolean = false;
  pagina: number = 1;
  sourceObservable: Observable<any>;
  tipo: TipoPoupResumo;

  constructor(
    private translateService: TranslateService,
    private datePipe: DatePipe,
    private contratoService: ContratoService,
    private dashboardService: DashboardService,
  ) {}

  ngOnInit(): void {
    this.contratoService.getContrato().subscribe((contrato) => {
      this.exibirIdTeste = contrato.flExibeIdTesteImpedimentoResultado;
    });
  }

  abrirPopup(tipo: TipoPoupResumo) {
    this.exibirPopup = true;
    this.tipo = tipo;
    this.configurarColunas(tipo);
    this.configurarHeader(tipo);
    this.atualizarSourceObservable();
  }

  atualizarSourceObservable(pagina?: number) {
    let sourceMap: any = {
      [TipoPoupResumo.USUARIOS_ATIVIDADE]: this.dashboardService.listarUsuariosCadastrados.bind(
        this.dashboardService,
      ),
      [TipoPoupResumo.AVALIACOES_REALIZADAS]: this.dashboardService.listarAvaliacoesRealizadas.bind(
        this.dashboardService,
      ),
      [TipoPoupResumo.USUARIOS_SEM_AVALIACAO]: this.dashboardService.listarUsuariosPendentes.bind(
        this.dashboardService,
      ),
    };
    this.pagina = pagina || 1;
    const filtros = this.filtros.toApi();

    this.sourceObservable = sourceMap[this.tipo](this.pagina, 30, filtros).pipe(
      tap((response: any) => this.adicionarColunaCatraca(response.dados, this.colunas)),
    );
  }

  adicionarColunaCatraca(response: ResponseResumoUsuarioTO[], colunas: any) {
    const possuiDhUltEntradaCatraca = response.some((item) => item.dhUltEntradaCatraca);
    const colunaAdicionada = colunas.find((c) => c.id == "coluna-catraca");
    if (possuiDhUltEntradaCatraca && !colunaAdicionada) {
      colunas.push({
        id: "coluna-catraca",
        header: this.translateService.instant("avaliacao.dhUltEntradaCatraca"),
        valueDisplayFn: (valor) =>
          valor?.dhUltEntradaCatraca
            ? this.datePipe.transform(moment(valor.dhUltEntradaCatraca).toDate(), "short")
            : "-",
        width: "15%",
      });
    }
  }

  configurarColunas(tipo: TipoPoupResumo) {
    this.colunas = [
      {
        header: this.translateService.instant("usuario.nomeTitulo"),
        field: "dsNome",
        width: "30%",
      },
      {
        header: this.translateService.instant("usuario.hierarquia"),
        field: "dsHierarquia",
        width: "30%",
      },
      {
        header: this.translateService.instant("usuario.matriculaColuna"),
        field: "cdMatricula",
        textHeaderAlign: "center",
        textAlign: "center",
        width: "30%",
      },
    ];

    if (tipo == TipoPoupResumo.USUARIOS_SEM_AVALIACAO) {
      this.colunas.push({
        header: this.translateService.instant("avaliacao.dtUltExecucao"),
        valueDisplayFn: (valor) =>
          valor?.dtUltExecucao ? this.datePipe.transform(moment(valor.dtUltExecucao).toDate(), "short") : "-",
        width: "15%",
      });
    } else if (tipo == TipoPoupResumo.AVALIACOES_REALIZADAS) {
      if (this.exibirIdTeste) {
        this.colunas.unshift({
          header: this.translateService.instant("avaliacao.idTeste"),
          width: "10%",
          field: "idTesteExecutado",
          textHeaderAlign: "center",
          textAlign: "center",
        });
        this.colunas.push(
          {
            header: this.translateService.instant("avaliacao.nivelResultado"),
            valueDisplayFn: (valor) => valor.dsNivelResultado,
            textHeaderAlign: "center",
            textAlign: "center",
          },
          {
            header: this.translateService.instant("avaliacao.dtUltExecucao"),
            width: "15%",
            valueDisplayFn: (valor) =>
              valor?.dtUltExecucao
                ? this.datePipe.transform(moment(valor.dtUltExecucao).toDate(), "short")
                : "-",
          },
          {
            header: this.translateService.instant("avaliacao.qtRealizado"),
            field: "qtRealizado",
          },
        );
      }
    }
  }

  configurarHeader(tipo: TipoPoupResumo) {
    let headersMap: any = {
      [TipoPoupResumo.USUARIOS_ATIVIDADE]: "dashboard.resumoTotal",
      [TipoPoupResumo.AVALIACOES_REALIZADAS]: "dashboard.resumoRealizado",
      [TipoPoupResumo.USUARIOS_SEM_AVALIACAO]: "dashboard.resumoPendente",
    };
    this.header = headersMap[tipo];
  }
}
