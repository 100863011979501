<app-popup-evolucao-temporal />

@if (detalhes) {
  <div class="p-grid p-mb-3">
    <div class="p-col-11">
      <div class="p-grid">
        @if (detalhes?.usuario?.dsUsuario) {
          <div class="p-col-6 p-md-3">
            <span class="label" translate>dashboard.nomeCompleto</span>
            <span class="valor">{{ detalhes?.usuario?.dsUsuario }}</span>
          </div>
        }
        @if (detalhes?.usuario?.cdMatricula) {
          <div class="p-col-6 p-md-3">
            <span class="label" translate>dashboard.matricula</span>
            <span class="valor">{{ detalhes?.usuario?.cdMatricula }}</span>
          </div>
        }
        @if (detalhes?.usuario?.dsCargo) {
          <div class="p-col-6 p-md-3">
            <span class="label" translate>dashboard.cargo</span>
            <span class="valor">{{ detalhes?.usuario?.dsCargo }}</span>
          </div>
        }
        @if (detalhes?.dsHierarquia) {
          <div class="p-col-6 p-md-3">
            <span class="label" translate>dashboard.hierarquia</span>
            <span class="valor">
              {{ detalhes?.dsHierarquia }}
            </span>
          </div>
        }
      </div>
    </div>
    <div class="p-col-1">
      @if (detalhes?.usuario?.idUltimoTesteExecutado) {
        <button
          class="sme-icon"
          [pTooltip]="'analiseProntidao.GraficoEvolucaoTemporal' | translate"
          (click)="exibirEvolucaoTemporal(detalhes)"
          tooltipPosition="top"
        >
          <em class="material-icons">trending_up</em>
        </button>
      }
    </div>
  </div>
}
