<div class="card">
  <div class="p-grid">
    @if (isIdContratoRequired) {
      <div class="p-col-12 p-md-4 p-mb-3">
        <label for="idContrato" class="label-input" translate> sinalizador.idContrato </label>
        <app-dropdown
          [(ngModel)]="idContrato"
          name="idContrato"
          appendTo="body"
          [options]="listaContratos"
          [required]="isIdContratoRequired"
          (onChange)="handleOnContratoChange($event)"
        />
      </div>
    }
    <div class="p-col-12 p-md-1 p-mb-3">
      @if (podeVisualizarValores) {
        <label translate> sinalizador.incluirValores </label>
        <div class="p-mt-2">
          <p-inputSwitch [(ngModel)]="exibirValores" />
        </div>
      }
    </div>
    <div class="p-col-12 p-md-7 p-mb-3">
      @if (exibirValores && idContrato) {
        <app-media-sinalizador-prontidao
          [media]="media"
          [loading]="loadingHierarquia || loadingUsuario || loadingCompilado"
        />
      }
    </div>
  </div>

  @if (showTable()) {
    <div class="header-container">
      @if (!isCompiladoActive(idxTabAtivo)) {
        <app-download-sinalizador-prontidao [filtros]="filtros" />
      }
      <app-legenda-sinalizador-prontidao />
    </div>
  }
  @if (showTable()) {
    <app-tab-container (tabChange)="handleOnTabChange($event)" [activeIndex]="idxTabAtivo">
      <app-tabs [title]="'sinalizador.hierarquias' | translate">
        <div class="content">
          <app-tabela-sinalizador-prontidao
            dataKey="idHierarquia"
            groupRowsBy="idHierarquia"
            [showNumeroTabela]="exibirValores"
            [customErroTabela]="customErroTabela"
            [tituloColunaNome]="'sinalizador.hierarquias' | translate"
            [(dados)]="dadosTabelaHierarquia"
            [loading]="loadingHierarquia"
            [useHierarquiaSort]="true"
            [dynamicTableRows]="dynamicTableRows"
            [configuracao]="configuracao"
            [podeExpandirHierarquias]="podeExpandirHierarquias"
            (expandedRowClicked)="getUsuariosByHierarquia($event)"
          />
        </div>
      </app-tabs>
      <app-tabs [title]="'sinalizador.usuarios' | translate" [show]="showAbaUsuarios">
        <div class="content">
          <app-tabela-sinalizador-prontidao
            dataKey="idUsuario"
            [showNumeroTabela]="exibirValores"
            [showColunaHierarquia]="true"
            [customErroTabela]="customErroTabela"
            [tituloColunaNome]="'sinalizador.usuarios' | translate"
            [dados]="dadosTabelaUsuario"
            [loading]="loadingUsuario"
            [configuracao]="configuracao"
            [dynamicTableRows]="15"
            [totalRecords]="qtdItensUsuarios"
            [lazy]="true"
            (ordenar)="handleOrdenacao($event)"
            (onPageChange)="handleOnPageChange($event)"
          />
        </div>
      </app-tabs>
      <app-tabs [title]="'sinalizador.compilado.tituloTab' | translate">
        <app-compilado-sinalizador-prontidao
          [hierarquiaNivel1]="dadosCompilado"
          [loading]="loadingCompilado"
          [filtros]="filtros"
        />
      </app-tabs>
    </app-tab-container>
  }
</div>

<app-filtros-sinalizador-prontidao
  [isCompilado]="isCompiladoActive(idxTabAtivo)"
  [filtrosIniciais]="filtros"
  (filtrar)="onFiltrar($event)"
/>
