<p-table
  id="tabela-sinalizador"
  [sortMode]="!useHierarquiaSort ? 'single' : null"
  [value]="dados"
  [dataKey]="dataKey"
  [groupRowsBy]="groupRowsBy"
  [rowGroupMode]="showChildren && 'subheader'"
  [scrollable]="true"
  [paginator]="true"
  [rows]="dynamicTableRows"
  [totalRecords]="totalRecords"
  [lazy]="lazy"
  (onPage)="onPageChange.emit($event)"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        class="text-column left"
        tooltipPosition="top"
        [pTooltip]="
          (isHierarquia ? 'sinalizador.dsHierarquiaTooltip' : 'sinalizador.dsNomeUsuarioTooltip') | translate
        "
        (click)="onChangeOrdenacao('dsHierarquia')"
        [pSortableColumn]="!useHierarquiaSort ? 'dsNomeUsuario' : null"
        translate
      >
        {{ tituloColunaNome }}
        @if (!useHierarquiaSort && showSortIcon("dsHierarquia")) {
          <p-sortIcon field="dsNomeUsuario"></p-sortIcon>
        }
        @if (useHierarquiaSort) {
          <ng-template
            [ngTemplateOutlet]="ordenacaoTemplate"
            [ngTemplateOutletContext]="{ $implicit: 'dsHierarquia' }"
          ></ng-template>
        }
      </th>
      @if (showColunaHierarquia) {
        <th
          class="text-column"
          tooltipPosition="top"
          [pTooltip]="'sinalizador.cdMatriculaTooltip' | translate"
          (click)="onChangeOrdenacao('cdMatricula')"
          pSortableColumn="cdMatricula"
          translate
        >
          usuario.matricula
          @if (!useHierarquiaSort && showSortIcon("cdMatricula")) {
            <p-sortIcon field="cdMatricula"></p-sortIcon>
          }
          @if (useHierarquiaSort) {
            <ng-template
              [ngTemplateOutlet]="ordenacaoTemplate"
              [ngTemplateOutletContext]="{ $implicit: 'cdMatricula' }"
            ></ng-template>
          }
        </th>
        <th
          class="text-column left"
          tooltipPosition="top"
          [pTooltip]="'sinalizador.dsHierarquiaTooltip' | translate"
          (click)="onChangeOrdenacao('dsNomeHierarquia')"
          [pSortableColumn]="!useHierarquiaSort ? 'dsNomeHierarquia' : null"
          translate
        >
          usuario.hierarquia
          @if (!useHierarquiaSort && showSortIcon("dsNomeHierarquia")) {
            <p-sortIcon field="dsNomeHierarquia"></p-sortIcon>
          }
          @if (useHierarquiaSort) {
            <ng-template
              [ngTemplateOutlet]="ordenacaoTemplate"
              [ngTemplateOutletContext]="{ $implicit: 'dsNomeHierarquia' }"
            ></ng-template>
          }
        </th>
      }
      <th
        tooltipPosition="top"
        [pTooltip]="'sinalizador.qtUsuariosTooltip' | translate"
        (click)="onChangeOrdenacao('qtUsuarios')"
        [pSortableColumn]="!useHierarquiaSort ? 'qtUsuarios' : null"
        translate
      >
        sinalizador.qtUsuarios
        @if (!useHierarquiaSort && showSortIcon("qtUsuarios")) {
          <p-sortIcon field="qtUsuarios"></p-sortIcon>
        }
        @if (useHierarquiaSort) {
          <ng-template
            [ngTemplateOutlet]="ordenacaoTemplate"
            [ngTemplateOutletContext]="{ $implicit: 'qtUsuarios' }"
          ></ng-template>
        }
      </th>
      <th
        tooltipPosition="top"
        [pTooltip]="'sinalizador.qtAvaliacoesTooltip' | translate"
        (click)="onChangeOrdenacao('qtAvaliacoes')"
        [pSortableColumn]="!useHierarquiaSort ? 'qtAvaliacoes' : null"
        translate
      >
        sinalizador.qtAvaliacoes
        @if (!useHierarquiaSort && showSortIcon("qtAvaliacoes")) {
          <p-sortIcon field="qtAvaliacoes"></p-sortIcon>
        }
        @if (useHierarquiaSort) {
          <ng-template
            [ngTemplateOutlet]="ordenacaoTemplate"
            [ngTemplateOutletContext]="{ $implicit: 'qtAvaliacoes' }"
          ></ng-template>
        }
      </th>
      <th
        tooltipPosition="top"
        [pTooltip]="'sinalizador.pcNivelNormalidadeTooltip' | translate"
        (click)="onChangeOrdenacao('pcNivelNormalidade')"
        [pSortableColumn]="!useHierarquiaSort ? 'pcNivelNormalidade' : null"
        translate
      >
        sinalizador.pcNivelNormalidade
        @if (!useHierarquiaSort && showSortIcon("pcNivelNormalidade")) {
          <p-sortIcon field="pcNivelNormalidade"></p-sortIcon>
        }
        @if (useHierarquiaSort) {
          <ng-template
            [ngTemplateOutlet]="ordenacaoTemplate"
            [ngTemplateOutletContext]="{ $implicit: 'pcNivelNormalidade' }"
          ></ng-template>
        }
      </th>
      <th
        tooltipPosition="top"
        [pTooltip]="'sinalizador.pcDefinicaoAcaoTooltip' | translate"
        (click)="onChangeOrdenacao('pcDefinicaoAcao')"
        [pSortableColumn]="!useHierarquiaSort ? 'pcDefinicaoAcao' : null"
        translate
      >
        sinalizador.pcDefinicaoAcao
        @if (!useHierarquiaSort && showSortIcon("pcDefinicaoAcao")) {
          <p-sortIcon field="pcDefinicaoAcao"></p-sortIcon>
        }
        @if (useHierarquiaSort) {
          <ng-template
            [ngTemplateOutlet]="ordenacaoTemplate"
            [ngTemplateOutletContext]="{ $implicit: 'pcDefinicaoAcao' }"
          ></ng-template>
        }
      </th>
      @if (configuracao?.flExibeNivel) {
        <th
          tooltipPosition="top"
          [pTooltip]="'sinalizador.pcNivelAlteradoTooltip' | translate"
          (click)="onChangeOrdenacao('pcNivelAlterado')"
          [pSortableColumn]="!useHierarquiaSort ? 'pcNivelAlterado' : null"
          translate
        >
          sinalizador.pcNivelAlterado
          @if (!useHierarquiaSort && showSortIcon("pcNivelAlterado")) {
            <p-sortIcon field="pcNivelAlterado"></p-sortIcon>
          }
          @if (useHierarquiaSort) {
            <ng-template
              [ngTemplateOutlet]="ordenacaoTemplate"
              [ngTemplateOutletContext]="{ $implicit: 'pcNivelAlterado' }"
            ></ng-template>
          }
        </th>
      }
      @if (configuracao?.flExibeAcoesCriticas) {
        <th
          tooltipPosition="top"
          [pTooltip]="'sinalizador.pcAcoesCriticasTooltip' | translate"
          (click)="onChangeOrdenacao('pcAcoesCriticas')"
          [pSortableColumn]="!useHierarquiaSort ? 'pcAcoesCriticas' : null"
          translate
        >
          sinalizador.pcAcoesCriticas
          @if (!useHierarquiaSort && showSortIcon("pcAcoesCriticas")) {
            <p-sortIcon field="pcAcoesCriticas"></p-sortIcon>
          }
          @if (useHierarquiaSort) {
            <ng-template
              [ngTemplateOutlet]="ordenacaoTemplate"
              [ngTemplateOutletContext]="{ $implicit: 'pcAcoesCriticas' }"
            ></ng-template>
          }
        </th>
      }
      @if (configuracao?.flExibeQueixas) {
        <th
          tooltipPosition="top"
          [pTooltip]="
            (isGerdau ? 'sinalizador.qtQueixasUsuariosTooltipGerdau' : 'sinalizador.qtQueixasUsuariosTooltip')
              | translate
          "
          (click)="onChangeOrdenacao('qtQueixasUsuarios')"
          [pSortableColumn]="!useHierarquiaSort ? 'qtQueixasUsuarios' : null"
          translate
        >
          {{ isGerdau ? "sinalizador.bemEstarGerdau" : "sinalizador.bemEstar" }}
          @if (!useHierarquiaSort && showSortIcon("qtQueixasUsuarios")) {
            <p-sortIcon field="qtQueixasUsuarios"></p-sortIcon>
          }
          @if (useHierarquiaSort) {
            <ng-template
              [ngTemplateOutlet]="ordenacaoTemplate"
              [ngTemplateOutletContext]="{ $implicit: 'qtQueixasUsuarios' }"
            ></ng-template>
          }
        </th>
      }
      @if (configuracao?.flExibePreditorRisco) {
        <th
          tooltipPosition="top"
          [pTooltip]="'sinalizador.pcPreditorRiscoTooltip' | translate"
          (click)="onChangeOrdenacao('pcPreditorRisco')"
          [pSortableColumn]="!useHierarquiaSort ? 'pcPreditorRisco' : null"
          translate
        >
          sinalizador.pcPreditorRisco
          @if (!useHierarquiaSort && showSortIcon("pcPreditorRisco")) {
            <p-sortIcon field="pcPreditorRisco"></p-sortIcon>
          }
          @if (useHierarquiaSort) {
            <ng-template
              [ngTemplateOutlet]="ordenacaoTemplate"
              [ngTemplateOutletContext]="{ $implicit: 'pcPreditorRisco' }"
            ></ng-template>
          }
        </th>
      }
      @if (configuracao?.flExibeFadiga) {
        <th
          tooltipPosition="top"
          [pTooltip]="'sinalizador.pcFadigaTooltip' | translate"
          (click)="onChangeOrdenacao('pcFadiga')"
          [pSortableColumn]="!useHierarquiaSort ? 'pcFadiga' : null"
          translate
        >
          sinalizador.pcFadiga
          @if (!useHierarquiaSort && showSortIcon("pcFadiga")) {
            <p-sortIcon field="pcFadiga"></p-sortIcon>
          }
          @if (useHierarquiaSort) {
            <ng-template
              [ngTemplateOutlet]="ordenacaoTemplate"
              [ngTemplateOutletContext]="{ $implicit: 'pcFadiga' }"
            ></ng-template>
          }
        </th>
      }
      <th
        data-tutorial="coluna-score-total"
        class="position-relative"
        tooltipPosition="top"
        [pTooltip]="'sinalizador.qtScoreTotalTooltip' | translate"
        (click)="onChangeOrdenacao('qtScoreTotal')"
        [pSortableColumn]="!useHierarquiaSort ? 'qtScoreTotal' : null"
        translate
      >
        sinalizador.qtScoreTotal
        @if (!useHierarquiaSort && showSortIcon("qtScoreTotal")) {
          <p-sortIcon field="qtScoreTotal"></p-sortIcon>
        }
        @if (useHierarquiaSort) {
          <ng-template
            [ngTemplateOutlet]="ordenacaoTemplate"
            [ngTemplateOutletContext]="{ $implicit: 'qtScoreTotal' }"
          ></ng-template>
        }
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="groupheader" let-sinalizador let-rowIndex="rowIndex" let-expanded="expanded">
    @if (sinalizador.dsHierarquia) {
      <tr pRowGroupHeader>
        <td class="text-column">
          @if (sinalizador.qtUsuarios > 0 && podeExpandirHierarquias) {
            <button
              type="button"
              pButton
              class="button-expand"
              [pRowToggler]="sinalizador"
              [disabled]="sinalizador.isLoading"
              (click)="expandedRowClicked.emit(sinalizador)"
              [loading]="sinalizador.isLoading"
            >
              @if (!sinalizador.isLoading) {
                <span class="button-icon {{ expanded ? 'pi pi-minus' : 'pi pi-plus' }}"></span>
              }
            </button>
          }
          <span class="font-bold ml-2" [title]="sinalizador.dsHierarquia">{{
            sinalizador.dsHierarquia
          }}</span>
        </td>
        <ng-container *ngTemplateOutlet="colunasComuns; context: { $implicit: sinalizador }"></ng-container>
      </tr>
    }
  </ng-template>
  <ng-template [pTemplate]="showChildren ? 'rowexpansion' : 'body'" let-sinalizador>
    @if (sinalizador.dsNomeUsuario) {
      <tr [ngClass]="showChildren ? 'row-expansion' : ''">
        <td [title]="sinalizador.dsNomeUsuario" class="text-column">
          {{ sinalizador.dsNomeUsuario }}
        </td>
        @if (showColunaHierarquia) {
          <td [title]="sinalizador.cdMatricula" class="text-column">
            {{ sinalizador.cdMatricula }}
          </td>
          <td [title]="sinalizador.dsNomeHierarquia" class="text-column">
            {{ sinalizador.dsNomeHierarquia }}
          </td>
        }
        <ng-container *ngTemplateOutlet="colunasComuns; context: { $implicit: sinalizador }"></ng-container>
      </tr>
    }
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="getLoadingColspan()" class="centralizar">
        <app-loader [loading]="loading" type="normal" />
        @if (!loading) {
          <span>
            {{ customErroTabela || "Nenhum registro encontrado" }}
          </span>
        }
      </td>
    </tr>
  </ng-template>
</p-table>

<ng-template #colunasComuns let-sinalizador>
  <td class="centralizar">
    @if (!sinalizador.dsNomeUsuario) {
      <span>
        {{ sinalizador.qtUsuarios || 0 | number }}/{{ sinalizador.qtUsuariosRegra ?? 0 | number }}
      </span>
    }
    <img class="icone" src="assets/images/icons/user.svg" alt="Usuário icon" />
  </td>
  <td class="centralizar">
    <span> {{ sinalizador.qtAvaliacoes || 0 | number }} </span>
    <img class="icone" src="assets/images/icons/planilha.svg" alt="Planilha icon" />
  </td>
  <td>
    <div class="align-center">{{ sinalizador.pcNivelNormalidade * 100 || 0 | number }}%</div>
  </td>
  <td>
    <div class="align-center">
      {{
        sinalizador.pcDefinicaoAcao != undefined && sinalizador.pcDefinicaoAcao != null
          ? (sinalizador.pcDefinicaoAcao * 100 | number) + "%"
          : "N/A"
      }}
    </div>
  </td>
  @if (configuracao?.flExibeNivel) {
    <td>
      <div class="align-center">
        @if (showNumeroTabela) {
          <span> {{ sinalizador.pcNivelAlterado * 100 || 0 | number }}% </span>
        }
        <app-barra-energia
          [fullWidth]="true"
          comparador=">"
          class="vertical"
          [valor]="sinalizador.qtScoreNivelAlterado"
          [vlMaximo]="1"
          [pTooltip]="
            (sinalizador.qtScoreNivelAlterado == 1 ? 'sinalizador.acimaMedia' : 'sinalizador.abaixoMedia')
              | translate
          "
          tooltipPosition="top"
        />
      </div>
    </td>
  }
  @if (configuracao?.flExibeAcoesCriticas) {
    <td>
      <div class="align-center">
        @if (showNumeroTabela) {
          <span> {{ sinalizador.pcAcoesCriticas * 100 || 0 | number }}% </span>
        }
        <app-barra-energia
          [fullWidth]="true"
          comparador=">"
          class="vertical"
          [valor]="sinalizador.qtScoreAcoesCriticas"
          [vlMaximo]="1"
          [pTooltip]="
            (sinalizador.qtScoreAcoesCriticas == 1 ? 'sinalizador.acimaMedia' : 'sinalizador.abaixoMedia')
              | translate
          "
          tooltipPosition="top"
        />
      </div>
    </td>
  }
  @if (configuracao?.flExibeQueixas) {
    <td>
      <div class="align-center">
        @if (showNumeroTabela) {
          <span>
            {{ sinalizador.qtQueixasUsuarios || 0 | number: "1.2-2" }}
          </span>
        }
        <app-barra-energia
          [fullWidth]="true"
          comparador=">"
          class="vertical"
          [valor]="sinalizador.qtScoreQueixasUsuarios"
          [vlMaximo]="1"
          [pTooltip]="
            (sinalizador.qtScoreQueixasUsuarios == 1 ? 'sinalizador.acimaMedia' : 'sinalizador.abaixoMedia')
              | translate
          "
          tooltipPosition="top"
        />
      </div>
    </td>
  }
  @if (configuracao?.flExibePreditorRisco) {
    <td>
      <div class="align-center">
        @if (showNumeroTabela) {
          <span> {{ sinalizador.pcPreditorRisco * 100 || 0 | number }}% </span>
        }
        <app-barra-energia
          [fullWidth]="true"
          comparador=">"
          class="vertical"
          [valor]="sinalizador.qtScorePreditorRisco"
          [vlMaximo]="1"
          [pTooltip]="
            (sinalizador.qtScorePreditorRisco == 1 ? 'sinalizador.acimaMedia' : 'sinalizador.abaixoMedia')
              | translate
          "
          tooltipPosition="top"
        />
      </div>
    </td>
  }
  @if (configuracao?.flExibeFadiga) {
    <td>
      <div class="align-center">
        @if (showNumeroTabela) {
          <span> {{ sinalizador.pcFadiga * 100 || 0 | number }}% </span>
        }
        <app-barra-energia
          [fullWidth]="true"
          comparador=">"
          class="vertical"
          [valor]="sinalizador.qtScoreFadiga"
          [vlMaximo]="1"
          [pTooltip]="
            (sinalizador.qtScoreFadiga == 1 ? 'sinalizador.acimaMedia' : 'sinalizador.abaixoMedia')
              | translate
          "
          tooltipPosition="top"
        />
      </div>
    </td>
  }
  <td>
    <div class="align-center">
      <app-barra-energia
        [fullWidth]="true"
        tooltipPosition="top"
        [pTooltip]="sinalizador.qtScoreTotal + ''"
        comparador=">"
        class="horizontal"
        [valor]="sinalizador.qtScoreTotal"
        [vlMaximo]="4"
      />
    </div>
  </td>
</ng-template>

<ng-template #ordenacaoTemplate let-coluna>
  <span>
    @if (coluna == sortColumn) {
      @if (sortDirection == "down") {
        <em class="pi pi-sort-amount-up-alt"></em>
      }
      @if (sortDirection == "up") {
        <em class="pi pi-sort-amount-down"></em>
      }
    }
  </span>
</ng-template>
