@if (!isLoading) {
  <div class="header">
    <div class="p-grid">
      <div class="p-col-12 p-md-6">
        <span class="nome-usuario"> {{ detalheAcaoADefinir.dsUsuario }} </span>
      </div>
      <div class="p-col-12 p-md-6">
        <div class="p-grid">
          <div class="p-md-6 p-col-12">
            <span class="label" translate> dashboard.matricula<span>:</span> </span>
            <span class="valor"> {{ detalheAcaoADefinir.cdMatricula }} </span>
          </div>
          <div class="p-md-6 p-col-12">
            <span class="label" translate> dashboard.dataDeExecucao<span>:</span> </span>
            <span class="valor">
              {{ detalheAcaoADefinir.dtExecucao | date: "short" }}
            </span>
          </div>
          <div class="p-md-6 p-col-12">
            <span class="label" translate>dashboard.cargo<span>:</span> </span>
            <span class="valor"> {{ detalheAcaoADefinir.dsCargo }} </span>
          </div>
          <div class="p-md-6 p-col-12">
            <span class="label" translate>dashboard.hierarquia<span>:</span> </span>
            <span class="valor"> {{ detalheAcaoADefinir.dsHierarquia }} </span>
          </div>
          @if (exibeId) {
            <div class="p-md-6 p-col-12">
              <span class="label" translate>ID<span>:</span></span>
              <span class="valor"> {{ detalheAcaoADefinir.idTesteExecutado }} </span>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
  <div class="secao">
    <h3 class="titulo" translate>
      dashboard.historioDesvioParametro
      <span class="titulo-info" translate> dashboard.ultimasAvaliacoes </span>
    </h3>
    <app-grafico-historico-testes
      [detalheAcaoADefinir]="detalheAcaoADefinir"
      [prontidaoGeral]="prontidaoGeral"
    />
  </div>
  @if (parametrosPrincipais?.length) {
    <div *appIfProduct class="secao">
      <h3 class="titulo --small" translate>
        dashboard.parametros
        <span class="titulo-info" translate> dashboard.percentualDesvios </span>
      </h3>
      <div class="container-classificacao">
        @for (item of parametrosPrincipais; track item) {
          <app-parametro-card
            [tooltip]="item.dsGrupoExplicacao"
            [descricao]="item.dsGrupo"
            [valor]="item.vlPerc"
          />
        }
      </div>
    </div>
  }
  @if (outrosParametros?.length) {
    <div *appIfProduct class="secao">
      <h3 class="titulo --small" translate>dashboard.outrosIndicadores</h3>
      <div class="container-classificacao">
        @for (item of outrosParametros; track item) {
          <app-parametro-card
            [tooltip]="item.dsGrupoExplicacao"
            [descricao]="item.dsGrupo"
            [valor]="item.vlPerc"
          />
        }
      </div>
    </div>
  }
  <div class="secao">
    <app-historico-acoes [historicoAcoes]="detalheAcaoADefinir.histAcao" />
  </div>
  @if (detalheAcaoADefinir?.histAcaoDefinida?.length) {
    <div class="secao">
      <app-historico-acoes-definidas [historico]="detalheAcaoADefinir.histAcaoDefinida" />
    </div>
  }
  <div class="secao">
    <app-informacoes-dia [testeADefinir]="detalheAcaoADefinir" />
  </div>
} @else {
  <div style="width: 100%">
    <div class="secao">
      <div class="p-grid">
        <div class="p-col-12 p-md-6">
          <p-skeleton height="40px" width="200px"></p-skeleton>
        </div>
        <div class="p-col-12 p-md-6">
          <div class="p-grid">
            @for (item of [1, 2, 3, 4]; track $index) {
              <div class="p-col-12 p-md-6">
                <p-skeleton height="32px" width="100%"></p-skeleton>
              </div>
            }
          </div>
        </div>
      </div>
    </div>

    <div class="secao">
      <div class="p-grid">
        <div class="p-col-12">
          <p-skeleton height="30px" width="200px"></p-skeleton>
        </div>
        <div class="p-col-12">
          <p-skeleton height="200px" width="100%"></p-skeleton>
        </div>
      </div>
    </div>

    <div class="secao">
      <div class="p-grid">
        <div class="p-col-12">
          <p-skeleton height="40px" width="200px"></p-skeleton>
        </div>
        @for (item of [1, 2, 3, 4]; track $index) {
          <div class="p-col-12 p-md-3">
            <p-skeleton height="135px" width="100%"></p-skeleton>
          </div>
        }
      </div>
    </div>
  </div>
}
