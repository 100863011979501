import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import html2canvas from "html2canvas";
import { TabPanel } from "primeng/tabview";
import { UsuarioService } from "src/app/shared/api-services/usuario.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { TipoDispositivoService } from "src/app/shared/services/tipo-dispositivo.service";
import { ResponseContratoHierarquiaTO } from "src/app/shared/tos/response-contrato-hierarquia.to";
import { SimpleCrudPage } from "../simple-crud-page";
import { RoleService } from "src/app/shared/services/role.service";
import { SinalizadorProntidaoComponent } from "./components/sinalizador-prontidao/sinalizador-prontidao.component";
import { UserGuideService } from "src/app/shared/services/user-guide.service";
import { dashboardTutorial } from "./dashboardTutorial";
import { UserGuideEnum } from "src/app/shared/enums/user-guide.enum";
import { Util } from "src/app/shared/services/util.service";
import { IndicadoresFadigaComponent } from "./indicadores-fadiga/indicadores-fadiga.component";
import { AvaliacoesProntidaoComponent } from "./avaliacoes-prontidao/avaliacoes-prontidao.component";
import { ConfiguracaoDashboardTO } from "src/app/shared/tos/configuracao-dashboard.to";
import { DashboardFacade } from "./facades/dashboard.facade.service";
import { SegurancaPsicologicaComponent } from "./seguranca-psicologica/seguranca-psicologica.component";

@Component({
  selector: "app-dashboard",
  templateUrl: "dashboard.component.html",
  styleUrls: ["dashboard.component.scss", "dashboard-print.component.scss"],
  providers: [UserGuideService],
})
export class DashboardComponent extends SimpleCrudPage implements OnInit, AfterViewInit {
  @ViewChild(AvaliacoesProntidaoComponent) avaliacoesProntidaoComponent: AvaliacoesProntidaoComponent;
  @ViewChild(SegurancaPsicologicaComponent) segurancaPsicologicaComponent: SegurancaPsicologicaComponent;
  @ViewChild("tabProntidao") tabProntidao: TabPanel;
  @ViewChild("tabIntrajornada") tabIntrajornada: TabPanel;
  @ViewChild("tabSeguranca") tabSeguranca: TabPanel;
  @ViewChild("tabFadiga") tabFadiga: TabPanel;
  @ViewChild("tabSinalizadorProntidao") tabSinalizadorProntidao: TabPanel;
  @ViewChild("sinalizadorProntidao") sinalizadorProntidao: SinalizadorProntidaoComponent;
  @ViewChild("indicadorFadiga") indicadorFadiga: IndicadoresFadigaComponent;

  configuracaoDashboard: ConfiguracaoDashboardTO = <ConfiguracaoDashboardTO>{};
  activeTab: number = 0;
  tutorialConfigurado = false;
  exibirColunaTestes: boolean = true;

  constructor(
    private activatedRouter: ActivatedRoute,
    private translateService: TranslateService,
    private routerService: RouterService,
    public readonly usuarioService: UsuarioService,
    private tipoDispositivoService: TipoDispositivoService,
    private userGuideService: UserGuideService,
    private roleService: RoleService,
    private dashboardFacade: DashboardFacade,
  ) {
    super(activatedRouter);
  }

  async ngOnInit() {
    this.configuracaoDashboard = await this.dashboardFacade.getConfiguracaoDashboard();
    if (this.configuracaoDashboard.redirecionarRelatorio) {
      this.routerService.toGerarRelatorio();
      return;
    }
  }

  toggleColunaTestes() {
    this.exibirColunaTestes = !this.exibirColunaTestes;
  }

  async ngAfterViewInit() {
    const hasConfiguracoes = await Util.waitUntil(() => !!this.configuracaoDashboard?.contrato);
    const continuarTutorial = this.activatedRouter.snapshot.queryParams["tutorial"] == "true";
    if (hasConfiguracoes) {
      this.tutorialConfigurado = true;
      this.configTutorial(this.configuracaoDashboard.contrato);
      if (continuarTutorial) {
        this.onRepetirTutorial();
      }
    }
  }

  onRepetirTutorial() {
    this.configTutorial(this.configuracaoDashboard.contrato);
    this.userGuideService.repeat();
  }

  async startTutorial(novidadesExibidas: boolean) {
    if (novidadesExibidas == false) {
      await Util.waitUntil(() => this.tutorialConfigurado);
      this.userGuideService.start(UserGuideEnum.DASHBOARD);
    }
  }

  configTutorial(configuracoes: ResponseContratoHierarquiaTO) {
    const changeTab = (index: number) => {
      if (this.activeTab != index) {
        this.activeTab = index;
        setTimeout(() => {
          this.handleChange();
        });
      }
    };

    const isGraficosLoaded = () =>
      this.avaliacoesProntidaoComponent?.listaGraficosExibir?.every(
        (grafico) => grafico.hasError || !!grafico.dados,
      );

    const isResumoLoaded = () => !this.avaliacoesProntidaoComponent.loaderResumo;

    const carregarDadosSinalizadorPrevencao = async (): Promise<boolean> => {
      await Util.waitUntil(() => !!this.sinalizadorProntidao);
      return (await this.sinalizadorProntidao.acessarPrimeiroElemento()) as any;
    };

    const carregarDadosIndicadorFadiga = () => this.indicadorFadiga.acessarPrimeiroCard();

    const navegarAcoesPendentesMobile = () => this.routerService.toListaTestePendente(true);

    const navegarParaDashboard = () => this.routerService.toDashboard();

    const isContinuacaoTutorial = this.activatedRouter.snapshot.queryParams["tutorial"] == "true";
    this.userGuideService.setSteps(
      dashboardTutorial(
        this.translateService,
        this.tipoDispositivoService.isMobile() || this.tipoDispositivoService.isTablet(),
        isContinuacaoTutorial,
        this.configuracaoDashboard.exibirAbaIntrajornada,
        this.configuracaoDashboard.exibirAbaSinalizadorProntidao,
        this.configuracaoDashboard.exibirAbaFadiga,
        this.roleService.isRole(RoleService.ACAO_DEFINIDA_REGEX),
        isResumoLoaded,
        isGraficosLoaded,
        changeTab,
        carregarDadosSinalizadorPrevencao,
        carregarDadosIndicadorFadiga,
        navegarAcoesPendentesMobile,
        navegarParaDashboard,
      ),
    );
  }

  exibirDialogoFiltroGrafico(): void {
    if (this.tabSinalizadorProntidao?.selected) {
      this.sinalizadorProntidao.openFiltros();
    } else if (this.tabFadiga?.selected) {
      this.indicadorFadiga.showFiltros();
    } else if (this.tabSeguranca?.selected) {
      this.segurancaPsicologicaComponent.openFiltros();
    } else {
      this.avaliacoesProntidaoComponent.openFiltros();
    }
  }

  handleChange() {
    this.exibirColunaTestes = this.tabProntidao?.selected || this.tabIntrajornada?.selected;
  }

  imprimirPagina() {
    if (!this.tipoDispositivoService.isFirefox()) {
      window.print();
      return;
    }
    const body = document.body;
    body.classList.add("print");
    html2canvas(body).then(async (canvas) => {
      setTimeout(async () => {
        const blob: any = await new Promise((resolve) => canvas.toBlob(resolve));
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
        body.classList.remove("print");
      }, 1000);
    });
  }
}
