import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { skipWhile, tap } from "rxjs/operators";

import { ResponseAlertaSeguranca } from "../tos/response/response-alerta-seguranca";

@Injectable({
  providedIn: "root",
})
export class AlertaSegurancaService {
  private lazyRequest = new Subject<ResponseAlertaSeguranca>();
  private cache = {};
  private static GET_ALERTA_KEY = "GET_ALERTA_KEY";
  private isFetching = false;

  constructor(private httpClient: HttpClient) {}

  private versionAPI: string = "/v1";
  private path: string = "/alerta-seguranca";

  getAlertaSeguranca(): Observable<ResponseAlertaSeguranca> {
    if (this.cache[AlertaSegurancaService.GET_ALERTA_KEY]) {
      return of(this.cache[AlertaSegurancaService.GET_ALERTA_KEY]);
    }
    if (this.isFetching) {
      return this.lazyRequest;
    }
    this.isFetching = true;
    return this.httpClient.get<ResponseAlertaSeguranca>(`${this.versionAPI + this.path}/`).pipe(
      tap(
        (r) => {
          this.cache[AlertaSegurancaService.GET_ALERTA_KEY] = r;
          this.lazyRequest.next(r);
        },
        undefined,
        () => (this.isFetching = false),
      ),
    );
  }
}
