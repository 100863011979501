export enum ParametroEnum {
  IMPULSIVIDADE_ESTIMADA = "IMPULSIVIDADE_ESTIMADA",
  ATENCAO = "ATENCAO",
  CONCENTRACAO = "CONCENTRACAO",
  CONTROLE_IMPULSO = "CONTROLE_IMPULSO",
  TEMPO_REACAO = "TEMPO_REACAO",
  FADIGA = "FADIGA",
  HUMOR = "HUMOR",
  COMP_INSEGURO = "COMP_INSEGURO",
  PRONTIDAO_GERAL = "PRONTIDAO_GERAL",
  INTRAJORNADA = "INTRAJORNADA",
  INCOMPLETOS = "INCOMPLETOS",
}
