@if (isAlerta) {
  <div class="alerta__pointer" (click)="openModal()">
    <img class="alerta--icone" src="/assets/images/sirene.gif" alt="Sirene" />
    <p class="alerta--texto" translate>alertaSeguranca.textoAlerta</p>
  </div>

  <p-dialog
    [modal]="true"
    [showHeader]="false"
    [(visible)]="showModal"
    [style]="{ 'border-radius': '10px', top: '-25%', width: '90%', 'max-width': '900px' }"
    [contentStyle]="{ padding: 0, 'border-radius': '10px' }"
  >
    <div class="fechar">
      <button class="fechar--button" type="button" title="Fechar" (click)="closeModal()">X</button>
    </div>
    <div class="alerta__modal">
      <img class="alerta--imagem" src="/assets/images/atalhos/prontos_azul.png" alt="Prontos" />
      <img class="alerta--icone" src="/assets/images/sirene.gif" alt="Sirene" />
      <p class="alerta--texto" translate [innerHTML]="'alertaSeguranca.textoAlertaPopup' | translate"></p>
    </div>
  </p-dialog>
}
