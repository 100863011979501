<div class="definir-acao-card">
  <div class="card-grupo">
    <h3 class="titulo" translate>dashboard.definirAcao</h3>
    @if (form.value) {
      <p class="instrucao">
        <span translate> dashboard.acaoDefinidaSingular</span>: {{ getAcaoLabel(form.value) }}
        <em class="pi pi-check-circle check-icon"></em>
      </p>
    } @else {
      <p class="instrucao" translate>dashboard.definirAcaoInstrucao</p>
    }
  </div>
  <div class="card-grupo">
    <button pButton class="sme-quaternary" (click)="abrirDefinirAcao()">
      {{ form.value ? ("dashboard.alterarAcao" | translate) : ("dashboard.selecioneAcao" | translate) }}
      <em class="pi pi-angle-right"></em>
    </button>
  </div>
</div>

<p-dialog
  [header]="'dashboard.tiposAcao' | translate"
  [(visible)]="exibirPopupDefinirAcao"
  modal="true"
  [style]="{ width: '90%', 'max-width': '650px' }"
>
  <div class="acao-sugerida-card">
    <em class="pi pi-info-circle"></em>
    <p class="acao-sugerida-grupo">
      <span class="label" translate> dashboard.acaoSugerida </span>
      <span class="valor" translate> {{ acaoSugerida }} </span>
    </p>
  </div>

  <form class="definir-acao-form" (submit)="handleSubmit()">
    @for (acao of acoes; track $index) {
      <div class="definir-acao-item">
        <p-radioButton [formControl]="definirAcaoForm" [value]="acao.id" [inputId]="acao.id" />
        <label [for]="acao.id"> {{ acao.dsAcao }} </label>
      </div>
    }
    <div class="botao-container">
      <button type="submit" pButton class="sme-quaternary" translate [disabled]="!definirAcaoForm.valid">
        botaoSalvar
      </button>
    </div>
  </form>
</p-dialog>
