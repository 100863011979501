import { Injectable } from "@angular/core";
import { ContratoService } from "src/app/shared/api-services/contrato.service";
import { HierarquiaService } from "src/app/shared/api-services/hierarquia.service";
import { Permissao } from "src/app/shared/enums/permissao.enum";
import { SimNaoEnum } from "src/app/shared/enums/sim-nao-enum";
import { RoleService } from "src/app/shared/services/role.service";
import { ConfiguracaoDashboardTO } from "src/app/shared/tos/configuracao-dashboard.to";

@Injectable({
  providedIn: "root",
})
export class DashboardFacade {
  constructor(
    private roleService: RoleService,
    private hierarquiaService: HierarquiaService,
    private contratoService: ContratoService,
  ) {}

  async getConfiguracaoDashboard(): Promise<ConfiguracaoDashboardTO> {
    const configuracoes = await this.contratoService.getContrato().toPromise();
    return {
      contrato: configuracoes,
      podeVerPendentestes: this.roleService.isRole(RoleService.ACAO_DEFINIDA_REGEX),
      redirecionarRelatorio: this.roleService.isRole(Permissao.SUPERVISOR_CHECKLIST),
      exibirAbaSegurancaPsicologica: await this.isSegurancaPsicologicaHabilitado(),
      exibirAbaSinalizadorProntidao:
        this.roleService.isRole("RL_VISUALIZAR_SINALIZADOR_PREVENCAO") &&
        configuracoes?.flExibeSinalizadorProntidao == SimNaoEnum.SIM,
      exibirAbaFadiga:
        this.roleService.isRole("RL_VISUALIZAR_INDICADOR_FADIGA") && configuracoes?.flExibeFadiga,
      exibirAbaIntrajornada: configuracoes.qtMinutosTesteIntrajornada > 0,
    };
  }

  private async isSegurancaPsicologicaHabilitado(): Promise<boolean> {
    try {
      const hasPermission = this.roleService.isRole("RL_VISUALIZAR_SEGURANCA_PSICOLOGICA");
      const idsHierarquia = this.hierarquiaService.getHierarquiasUsuario();
      const exibirAbaSegurancaPsicologica =
        hasPermission &&
        (await this.hierarquiaService.isSegurancaPsicologicaHabilitado(idsHierarquia).toPromise());
      return exibirAbaSegurancaPsicologica;
    } catch (err) {
      console.error("Erro ao verificar aba segurança psicologica", err);
      return false;
    }
  }

  getFiltrosProntidao() {}
}
