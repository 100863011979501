<app-filtros-dashboard />

@if (loading) {
  <div class="p-grid">
    @for (i of [1, 2]; track i) {
      <div class="p-col-12 p-md-6">
        <p-skeleton borderRadius="17px" height="65px"></p-skeleton>
      </div>
    }
    <div class="p-col-12">
      <p-skeleton borderRadius="17px" height="470px"></p-skeleton>
    </div>
  </div>
} @else {
  <div class="p-grid p-m-md-0">
    <div class="p-col-12 p-md-6">
      <app-box-dashboard
        [title]="('segurancaoPsicologica.periodo' | translate) + ':'"
        [value]="periodoAnalisado"
      ></app-box-dashboard>
    </div>
    <div class="p-col-12 p-md-6">
      <app-box-dashboard
        [title]="('segurancaoPsicologica.avaliacoes' | translate) + ':'"
        [value]="total"
        [bigger]="true"
      ></app-box-dashboard>
    </div>
    <div class="p-col-12">
      <app-grafico-seguranca-psicologica [dadosGrafico]="dados"></app-grafico-seguranca-psicologica>
    </div>
  </div>
}
