import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Chart } from "angular-highcharts";
import { AxisLabelsFormatterContextObject, PointOptionsObject } from "highcharts";
import moment from "moment";
import {
  DetalheAcaoADefinir,
  DetalheAcaoADefinirV3,
} from "src/app/shared/models/detalhe-acao-a-definir.model";
import { HistoricoNivelFadiga } from "src/app/shared/models/historico-nivel-fadiga,model";
import { HistoricoNivelResultado } from "src/app/shared/models/historio-nivel-resultado.model";

export enum TipoGraficoHistoricoTeste {
  PRONTIDAO = "PRONTIDAO",
  INTRAJORNADA = "INTRAJORNADA",
  FADIGA = "FADIGA",
}

interface SerieGrafico {
  type: TipoGraficoHistoricoTeste;
  name: any;
  y: number;
  x: number;
}

export interface DadosGraficoHistoricoTestes {
  historicoResultado: SerieGrafico[];
  historicoFadiga: SerieGrafico[];
  indiceProntidaoGeral: number;
}

@Injectable()
export class GraficoHistoricoTestesService {
  constructor(
    private translateService: TranslateService,
    private datePipe: DatePipe,
  ) {}

  private prepararHistoricoResultado(
    itensProntidao: HistoricoNivelResultado[],
    itensIntrajornada: HistoricoNivelResultado[],
  ): SerieGrafico[] {
    const formatarDado = (itens: HistoricoNivelResultado[], type: TipoGraficoHistoricoTeste) => {
      return itens
        .filter((item) => item.vlItem !== null && item.vlItem !== undefined)
        .map((item) => ({
          type: type,
          name: moment(item.dtExecucao).format("DD/MM/YYYY HH:mm"),
          y: item.vlItem,
          x: new Date(item.dtExecucao).getTime(),
        }));
    };
    const dadosProntidao = formatarDado(itensProntidao, TipoGraficoHistoricoTeste.PRONTIDAO);
    const dadosIntrajornada = formatarDado(itensIntrajornada, TipoGraficoHistoricoTeste.INTRAJORNADA);
    const dadosMergeados = [...dadosProntidao, ...dadosIntrajornada];
    dadosMergeados.sort((a, b) => a.x - b.x);
    return dadosMergeados;
  }

  private prepararFadiga(itens: HistoricoNivelFadiga[]): SerieGrafico[] {
    return itens
      .filter((item) => item.vlScoreFadiga !== null && item.vlScoreFadiga !== undefined)
      .map((item) => ({
        type: TipoGraficoHistoricoTeste.FADIGA,
        name: moment(item.dtExecucao).format("DD/MM/YYYY HH:mm"),
        y: item.vlScoreFadiga,
        x: new Date(item.dtExecucao).getTime(),
      }));
  }

  prepararDadosParaGrafico(detalheAcaoDefinir: DetalheAcaoADefinirV3): DadosGraficoHistoricoTestes {
    const historicoResultado = this.prepararHistoricoResultado(
      detalheAcaoDefinir.histNivelResultado || [],
      detalheAcaoDefinir.histNivelResultadoIntrajornada || [],
    );
    const historicoFadiga = this.prepararFadiga(detalheAcaoDefinir.histFadiga || []);
    return { historicoResultado, historicoFadiga, indiceProntidaoGeral: 0 };
  }

  configurarGrafico(dados: DadosGraficoHistoricoTestes): Chart {
    const getTickPositions = () => {
      const diasInseridos = [];
      const ticks = [];
      const datas = [...dados.historicoFadiga, ...dados.historicoResultado].map((dado) => dado.x);
      datas.sort((a, b) => a - b);
      datas.forEach((data) => {
        const dia = moment(data).day();
        if (!diasInseridos.includes(dia)) {
          diasInseridos.push(dia);
          ticks.push(moment(data).startOf("day").toDate().getTime());
        }
      });
      return ticks;
    };

    const getFadigaIcon = (valor: number) => {
      const simbolosFadiga = {
        vermelho: "icone-fadiga-vermelho.svg",
        amareloEscuro: "icone-fadiga-amarelo-escuro.svg",
        amarelo: "icone-fadiga-amarelo.svg",
        verde: "icone-fadiga-verde.svg",
      };
      let simbolo = "verde";
      if (valor < 30) {
        simbolo = "vermelho";
      } else if (valor >= 30 && valor < 50) {
        simbolo = "amareloEscuro";
      } else if (valor >= 50 && valor < 70) {
        simbolo = "amarelo";
      }
      return simbolosFadiga[simbolo];
    };

    const yTickPositions = [0, 25, 50, 75, 100];
    const yLabels = {
      0: this.translateService.instant("detalheAcaoADefinir.compInseguro"),
      25: this.translateService.instant("detalheAcaoADefinir.sigAlterado"),
      50: this.translateService.instant("detalheAcaoADefinir.alterado"),
      75: this.translateService.instant("detalheAcaoADefinir.limitrofe"),
      100: this.translateService.instant("detalheAcaoADefinir.normal"),
    };

    const historicoResultado: PointOptionsObject[] = dados.historicoResultado.map((ponto) => ({
      ...ponto,
      marker: {
        symbol: ponto.type == TipoGraficoHistoricoTeste.PRONTIDAO ? "circle" : "square",
      },
    }));

    const historicoFadiga: PointOptionsObject[] = dados.historicoFadiga.map((ponto) => {
      return {
        ...ponto,
        marker: {
          symbol: `url(${location.origin}/assets/images/icons/${getFadigaIcon(ponto.y)})`,
          height: 20,
          width: 14,
        },
      };
    });

    const chart = new Chart({
      chart: {
        backgroundColor: "#f5f5f5",
      },
      title: {
        text: "",
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        type: "datetime",
        tickPositions: getTickPositions(),
        labels: {
          enabled: true,
          formatter: (event) => {
            return this.datePipe.transform(event.value, "shortDate");
          },
          rotation: -25,
        },
      },
      yAxis: {
        min: 0,
        max: 100,
        tickPositions: yTickPositions,
        gridLineDashStyle: "Dash",
        gridLineColor: "#BDBDBD",
        title: {
          text: null,
        },
        labels: {
          enabled: true,
          formatter: (event: AxisLabelsFormatterContextObject) => {
            return yLabels[event.pos];
          },
        },
        plotBands: [
          {
            from: 0,
            to: 50,
            color: "#FF00330D",
          },
        ],
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true,
          },
        },
      },
      tooltip: {
        formatter: ((): any => {
          const self = this;
          return function () {
            return `${this.point.name} <br/> <b>${self.translateService.instant("detalheAcaoADefinir." + this.point.type.toLowerCase())}:</b> ${this.y}%`;
          };
        })(),
      },
      series: [
        {
          type: "line",
          color: "#5A739D",
          name: this.translateService.instant("detalheAcaoADefinir.prontidao"),
          data: historicoResultado,
        },
        {
          type: "line",
          dashStyle: "Dash",
          color: "#00000080",
          name: this.translateService.instant("detalheAcaoADefinir.indiceFadiga"),
          data: historicoFadiga,
        },
      ],
    });

    return chart;
  }

  filtrar(grafico: Chart, tipo: TipoGraficoHistoricoTeste, flExibir: boolean) {
    const isHistoricoTeste =
      tipo == TipoGraficoHistoricoTeste.PRONTIDAO || tipo == TipoGraficoHistoricoTeste.INTRAJORNADA;
    const seriesIndex = isHistoricoTeste ? 0 : 1;
    const series = grafico.ref.series[seriesIndex];

    const togglePoint = (point) => {
      point.update({ visible: flExibir });
    };

    if (!isHistoricoTeste) {
      series.points.forEach(togglePoint);
    } else {
      series.points.filter((p: any) => p.type == tipo).forEach(togglePoint);
    }
  }
}
