import { Util } from "../services/util.service";
import { RespostaEntrevistaDirecionada } from "./resposta-entrevista-direcionada";

export class TesteExecutadoAcaoDefinida {
  idAcaoDefinida: number;
  dsJustificativaAcao: string;
  respostasEntrevistaDirecionada: RespostaEntrevistaDirecionada[];

  toApi(
    form: any,
    comentarioPreDefinido: string,
    respostasEntrevistaDirecionada: RespostaEntrevistaDirecionada[],
  ) {
    Object.assign(this, form);

    if (comentarioPreDefinido) {
      this.dsJustificativaAcao =
        comentarioPreDefinido + (this.dsJustificativaAcao ? "<br/><br/>" + this.dsJustificativaAcao : "");
    }

    this.respostasEntrevistaDirecionada = Util.deepClone(respostasEntrevistaDirecionada);
    return this;
  }
}
