<!-- FILTRO DADOS DASHBOARD-->
<p-dialog
  id="idDialogFiltrosDashboard"
  [modal]="true"
  [style]="{ width: '90%', 'max-width': '765px' }"
  maskStyleClass="overlayFiltros"
  [showHeader]="true"
  [header]="'dashboard.tituloFiltro' | translate"
  [(visible)]="showDialogFiltroGrafico"
  [closable]="true"
>
  <form (submit)="handleSubmit()">
    <input type="hidden" />
    <div class="p-grid">
      <div class="p-col-6 p-md-3">
        <label for="dtAnalise" class="label-input" translate>dashboard.filtroGraficoDtAnalise</label>
        <p-calendar
          appendTo="body"
          class="calendar"
          [monthNavigator]="true"
          dateFormat="dd/mm/yy"
          [locale]="'calendar' | translate"
          [yearNavigator]="true"
          [yearRange]="yearRange"
          [showTime]="false"
          [(ngModel)]="dadosFiltroGraficoDashboard['dtAnalise']"
          name="dadosFiltroGraficoDashboard['dtAnalise']"
          [minDate]="minimoDiaMes"
          required
        ></p-calendar>
      </div>
      <div class="p-col-6 p-md-3">
        <label for="scoreFadiga" class="label-input" translate>dashboard.scoreFadiga</label>
        <app-dropdown
          name="dadosFiltroGraficoDashboard.scoreFadiga"
          [options]="listaScoreFadiga"
          [(ngModel)]="dadosFiltroGraficoDashboard.scoreFadiga"
        >
        </app-dropdown>
      </div>
    </div>

    <div class="p-grid">
      <div class="p-col-12">
        <app-turno-field
          [(ngModel)]="dadosFiltroGraficoDashboard.idTurno"
          name="idTurno"
          [fetchTurnos]="false"
          [listaTurnos]="listaTurnos"
        />
      </div>

      <div class="p-col-12">
        <app-cargo-field
          [(ngModel)]="dadosFiltroGraficoDashboard.idCargo"
          name="idCargo"
          [fetchCargos]="false"
          [listaCargos]="listaCargos"
        />
      </div>

      <div class="p-col-12">
        <app-hierarquia-field
          scrollHeight="150px"
          [(ngModel)]="dadosFiltroGraficoDashboard.idHierarquia"
          [fetchHierarquias]="false"
          [hierarquiasTreeNode]="hierarquias"
          name="hierarquiaSelecionadaFiltroDashboard"
        />
        @if (!dadosFiltroGraficoDashboard.idHierarquia?.length) {
          <small class="p-error" translate> erros.required </small>
        }
      </div>

      <div class="p-col-12 p-md-3">
        <label>
          <p-checkbox
            [label]="'dashboard.filtroIncluirFilhas' | translate"
            [binary]="true"
            name="dadosFiltroGraficoDashboard.flIncluirFilhas"
            [(ngModel)]="dadosFiltroGraficoDashboard.flIncluirFilhas"
          >
          </p-checkbox>
        </label>
      </div>

      <div class="p-col-12">
        <div class="p-grid">
          <div class="p-col-6">
            <button type="button" class="botao-cancelar" (click)="fecharDialogoFiltroGrafico()">
              <span translate>dashboard.cancelar</span>
            </button>
          </div>
          <div class="p-col-6">
            <button pButton [disabled]="!isFormValid()" type="submit" class="botao-confirmar">
              <span translate>dashboard.confirmar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</p-dialog>
