<form [formGroup]="formGroup">
  <app-card-definir-acao
    [acoes]="detalheAcaoADefinir.acaoDefinir"
    [acaoSugerida]="detalheAcaoADefinir?.dsAcaoSugerida"
    [form]="formGroup.get('idAcaoDefinida')"
  />

  @if (isAcaoPreDefinida && comentarioPreDefinido) {
    <p class="p-mb-5">
      <label class="label-comentario">
        <span translate> dashboard.escritoPor </span>
        {{ detalheAcaoADefinir?.preDefinicaoAcao?.dsUsuario }}:
      </label>
      <span> {{ comentarioPreDefinido }} </span>
    </p>
  }

  <div class="form-group">
    <label class="label-comentario" translate> dashboard.comentario </label>
    <textarea
      #inputTextCount
      autoResize="false"
      maxlength="2000"
      pInputTextarea
      rows="5"
      [placeholder]="'dashboard.comentarioPlaceholder' | translate"
      formControlName="dsJustificativaAcao"
    ></textarea>
    <div class="caracteres-restantes">{{ inputTextCount.value.length }} / 2000</div>
  </div>

  <div class="container-buttons" style="margin-top: 40px">
    <button
      pButton
      [disabled]="!formGroup.valid || loadingButtonSalvar"
      (click)="definirAcao()"
      [label]="'botaoSalvar' | translate"
      type="button"
      pButton
      class="sme-secondary"
      id="salvar-teste"
      [loading]="loadingButtonSalvar"
    ></button>

    @if (exibirEntrevistaDirecionada) {
      <button
        id="btn-entrevista-direcionada"
        pButton
        label="Entrevista Direcionada"
        type="button"
        class="sme-quaternary"
        (click)="showPopupEntrevista()"
      ></button>
    }
  </div>
</form>

@if (exibirEntrevistaDirecionada) {
  <app-popup-entrevista-direcionada #popupEntrevistaDirecionada (confirmar)="setRespostas($event)" />
}
