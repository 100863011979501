<app-filtros-dashboard />

<app-popup-detalhes-resumo #detalhesResumo [filtros]="filtros" />

@if (exibirResumoPopup) {
  <app-grafico-resumo-popup
    [titulo]="tituloResumo"
    [(isPopupVisible)]="exibirResumoPopup"
    totalRegistros="listaResumoGraficoEstatiscaAcoes?.qtTotalRegistros"
    [dados]="listaPopupResumo?.dados"
    [showColunaDtUltimaExecucao]="showColumaDataPopupResumo"
    [labelDtUltimaExecucao]="labelDataUltimoTeste"
    [loading]="loading"
  />
}

<div class="p-grid">
  <div class="p-col-12" [class.p-xl-8]="exibirColunaTestes">
    @if (!loaderResumo) {
      <app-informacoes-header [detalhes]="resumo" />

      <div class="p-grid p-mb-4">
        <div class="p-col-12 p-md-4" data-tutorial="usuario-atividade">
          <app-resumo-card
            value="{{ resumo.qtTotal | number }} / {{ resumo.qtTotalGeral | number }}"
            [title]="'dashboard.resumoTotal' | translate"
            [disabled]="readOnly"
            (click)="!readOnly && detalhesResumo.abrirPopup('USUARIOS_ATIVIDADE')"
          >
            <ng-template #badgeContent>
              <app-resumo-badge-content [resumo]="resumo"></app-resumo-badge-content>
            </ng-template>
          </app-resumo-card>
        </div>
        <div class="p-col-12 p-md-4" data-tutorial="avaliacoes-realizadas">
          <app-resumo-card
            [value]="resumo.qtRealizado | number"
            [title]="'dashboard.resumoRealizado' | translate"
            [disabled]="readOnly"
            (click)="!readOnly && detalhesResumo.abrirPopup('AVALIACOES_REALIZADAS')"
          />
        </div>
        <div class="p-col-12 p-md-4" data-tutorial="usuarios-sem-avaliacoes">
          <app-resumo-card
            [value]="resumo.qtPendente | number"
            [title]="'dashboard.resumoPendente' | translate"
            [disabled]="readOnly"
            (click)="!readOnly && detalhesResumo.abrirPopup('USUARIOS_SEM_AVALIACAO')"
          />
        </div>
      </div>
    } @else {
      <div class="p-grid p-mb-4">
        @for (i of [1, 2, 3]; track i) {
          <div class="p-col-12 p-md-4">
            <p-skeleton borderRadius="17px" height="66px"></p-skeleton>
          </div>
        }
      </div>
    }
    <div class="cards-graficos p-grid">
      @for (item of listaGraficosExibir; track item; let i = $index, last = $last) {
        @if (!item.hasError) {
          @if (!item.isLoading) {
            <div [id]="i" class="p-col-12 p-md-6" [attr.data-tutorial]="'grafico-' + item.dados.dsChave">
              <div class="container-grafico card">
                <app-card-chart-container
                  [isFilter]="item.dados.isFilter"
                  [type]="item.dados.tpGrafico.toUpperCase()"
                  [opcoesFiltro]="item.dados.opcoesFiltro"
                  [data]="item.dados"
                  (valorId)="recebeIdPergunta($event, item.dados)"
                />
              </div>
              @if (!last && i > 0 && (i == 1 || i % 4 === 0)) {
                <div class="print-break-page"></div>
              }
            </div>
          } @else {
            <div class="p-col-12 p-md-6">
              <p-skeleton borderRadius="17px" height="380px" width="100%"></p-skeleton>
            </div>
          }
        }
      }
    </div>
  </div>
  <div class="p-col-12 p-xl-4 hide-mobile" [class.hidden]="!exibirColunaTestes">
    <app-alerta-dashboard />
    @if (exibirColunaTestes) {
      <app-testes-pendentes-list [responsible]="false" [exibirTitulo]="false" />
    }
  </div>
</div>
