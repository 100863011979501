import { Component, Input } from "@angular/core";
import { HistoricoAcao } from "src/app/shared/models/historico-acao.model";

@Component({
  selector: "app-historico-acoes",
  templateUrl: "./historico-acoes.component.html",
  styleUrl: "./historico-acoes.component.scss",
})
export class HistoricoAcoesComponent {
  @Input() historicoAcoes: HistoricoAcao[];
  showPopupComentarioCompleto: boolean = false;
  comentarioCompleto: string;

  exibirComentarioCompleto(comentarioCompleto: string) {
    this.showPopupComentarioCompleto = true;
    this.comentarioCompleto = comentarioCompleto;
  }
}
