import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FiltroGraficoDashboad } from "src/app/shared/models/filtro-grafico-dashboard";
import { DashboardService } from "src/app/shared/services/dashboard.service";
import { ResponseGraficoSegurancaPsicologicaTO } from "src/app/shared/tos/response/response-grafico-segurancao-psicologica.to";
import { DashboardState } from "../facades/dashboard.state.service";
import { Subscription } from "rxjs";
import { FiltrosDashboardComponent } from "../components/filtros-dashboard/filtros-dashboard.component";

@Component({
  selector: "app-seguranca-psicologica",
  templateUrl: "./seguranca-psicologica.component.html",
  styleUrls: ["./seguranca-psicologica.component.scss"],
})
export class SegurancaPsicologicaComponent implements OnInit, OnDestroy {
  @ViewChild(FiltrosDashboardComponent) filtrosDashboardComponent: FiltrosDashboardComponent;
  @Input() dados: ResponseGraficoSegurancaPsicologicaTO[];
  @Input() filtros: FiltroGraficoDashboad;

  total: number;
  periodoAnalisado: string = "-";
  loading: boolean;
  subs: Subscription[] = [];

  constructor(
    private dashboardService: DashboardService,
    private dashboardState: DashboardState,
  ) {}

  ngOnInit(): void {
    const sub1 = this.dashboardState.getFiltrosProntidao().subscribe((filtros) => {
      this.filtros = filtros;
      this.periodoAnalisado = this.calcularPeriodoAnalisado(filtros);
      this.getDadosSegurancaPsicologica(this.filtros);
    });
    this.subs.push(sub1);
  }

  calcularTotalAvaliacoes(dados: ResponseGraficoSegurancaPsicologicaTO[]) {
    return dados ? dados.reduce((prev, cur) => prev + cur.qtRegistros, 0) : 0;
  }

  calcularPeriodoAnalisado(filtros: FiltroGraficoDashboad) {
    if (!filtros) return "-";
    return `${new Date(filtros.dtInicio).toLocaleDateString()} - ${new Date(filtros.dtFim).toLocaleDateString()}`;
  }

  getDadosSegurancaPsicologica(filtros: FiltroGraficoDashboad) {
    this.loading = true;
    this.dashboardService.getGraficoSegurancaPsicologica(filtros.toApi()).subscribe(
      (r) => {
        this.loading = false;
        this.dados = r;
        this.total = this.calcularTotalAvaliacoes(this.dados);
      },
      (err) => {
        this.loading = false;
        throw err;
      },
    );
  }

  openFiltros() {
    this.filtrosDashboardComponent.showFiltros();
  }

  ngOnDestroy(): void {
    this.subs?.forEach((s) => s.unsubscribe());
    this.filtrosDashboardComponent?.fecharDialogoFiltroGrafico();
  }
}
