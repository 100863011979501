<p-dialog
  modal="true"
  [style]="{ width: '90%', 'max-width': '1200px' }"
  [blockScroll]="true"
  [header]="header | translate"
  [(visible)]="exibirPopup"
  [draggable]="false"
>
  @if (exibirPopup) {
    <prontos-table
      [showHeader]="true"
      [selectWithRadioButton]="false"
      [lazy]="true"
      [columns]="colunas"
      [rows]="30"
      [first]="1"
      [filter]="false"
      [selectWithCheckbox]="false"
      (pageChange)="atualizarSourceObservable($event)"
      [sourceObservable]="sourceObservable"
      [page]="pagina"
    />
  }
</p-dialog>
