import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/app/shared/shared.module";
import { DetalheAcaoADefinirComponent } from "./detalhe-acao-a-definir.component";
import { PopupEntrevistaDirecionadaComponent } from "./popup-entrevista-direcionada/popup-entrevista-direcionada.component";
import { SkeletonModule } from "primeng/skeleton";
import { GraficoIndicadorFadigaComponent } from "../dashboard/components/grafico-indicador-fadiga/grafico-indicador-fadiga.component";
import { ParametroCardComponent } from "./parametro-card/parametro-card.component";
import { HistoricoAcoesComponent } from "./historico-acoes/historico-acoes.component";
import { InformacoesDiaComponent } from "./informacoes-dia/informacoes-dia.component";
import { HistoricoAcoesDefinidasComponent } from "./historico-acoes-definidas/historico-acoes-definidas.component";
import { GraficoHistoricoTestesComponent } from "./grafico-historico-testes/grafico-historico-testes.component";

@NgModule({
  declarations: [
    DetalheAcaoADefinirComponent,
    PopupEntrevistaDirecionadaComponent,
    ParametroCardComponent,
    HistoricoAcoesComponent,
    InformacoesDiaComponent,
    HistoricoAcoesDefinidasComponent,
    GraficoHistoricoTestesComponent,
  ],
  imports: [CommonModule, SharedModule, SkeletonModule, GraficoIndicadorFadigaComponent],
  exports: [DetalheAcaoADefinirComponent, PopupEntrevistaDirecionadaComponent],
})
export class DetalheAcaoADefinirModule {}
