<div class="grafico-box">
  <div class="header">
    <div>
      <div class="titulo" translate>
        detalheAcaoADefinir.testes
        <div class="borda"></div>
      </div>
      <div class="legenda">
        <div class="legenda-item">
          <input
            type="checkbox"
            name="legenda"
            value="FADIGA"
            id="fadiga"
            checked="true"
            (change)="filtrarGrafico($event)"
          />
          <label for="fadiga" translate>
            <img src="/assets/images/icons/icone-fadiga-preto.svg" />
            detalheAcaoADefinir.indiceFadiga
          </label>
        </div>
        <div class="legenda-item">
          <input
            type="checkbox"
            name="legenda"
            value="PRONTIDAO"
            id="prontidao"
            checked="true"
            (change)="filtrarGrafico($event)"
          />
          <label for="prontidao" translate>
            <div class="circulo"></div>
            detalheAcaoADefinir.prontidao
          </label>
        </div>
        <div class="legenda-item">
          <input
            type="checkbox"
            name="legenda"
            value="INTRAJORNADA"
            id="intrajornada"
            checked="true"
            (change)="filtrarGrafico($event)"
          />
          <label for="intrajornada" translate>
            <div class="quadrado"></div>
            detalheAcaoADefinir.intrajornada
          </label>
        </div>
      </div>
    </div>
    <div class="indice-geral" translate>
      detalheAcaoADefinir.indiceGeral <span class="valor"> {{ prontidaoGeral || 0 | number }}%</span>
    </div>
  </div>
  <div [chart]="chart" class="grafico"></div>
</div>
